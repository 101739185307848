import React from "react";
import VerificationInput from "react-verification-input";
import styled, { keyframes } from "styled-components";
import { Overlay } from "./StyledComponents";

interface Props {
  length: number;
  onChange: (args: any) => void;
  value: any;
  validChars?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

const CodeInput: React.FC<Props> = ({
  length,
  onChange,
  value,
  validChars="0-9",
  autoFocus=true,
  isLoading,
  isInvalid,
  isDisabled,
}) => {
  return (
    <VerificationInputWrapper isInvalid={isInvalid} isDisabled={isDisabled}>
      <VerificationInput
        autoFocus={autoFocus}
        length={length}
        onChange={onChange}
        value={value}
        validChars={validChars}
      />
      {isLoading && (
        <Overlay>
          <SpinnerBlue/>
        </Overlay>
      )}
    </VerificationInputWrapper>
  );
}

export default CodeInput;

const cursorAnimation = keyframes`
  0% {
    background-color: #777;
  }
  50% {
    background-color: #777;
  }
  50.00001% {
    background-color: inherit;
  }
  100% {
    background-color: inherit;
  }
`;

const VerificationInputWrapper = styled.div<{ isInvalid: boolean, isDisabled: boolean }>`
  margin: 0 auto 36px;
  position: relative;

  & input{
    cursor: default;
  }

  & .vi__container{
    margin: 0 auto;
    justify-content: center;
    height: 50px;
    gap: 10px;
  }

  & .vi__character{
    cursor: text;
    max-width: 40px;
    position: relative;
    border-radius: 4px;

    border-color: ${p =>
      p.isDisabled
        ? p.theme.palette.grey[2]
        : p.isInvalid
          ? p.theme.palette.brand.red
          : p.theme.palette.grey[3]};
    background-color: ${p => p.theme.palette.grey[1]};
    color: ${p => p.isDisabled ? p.theme.palette.grey[4] : p.theme.palette.brand.darkBlue};
    font-size: 18px;
    font-weight: normal;
    line-height: 48px;

    ${p => p.isDisabled
      ? `
        cursor: default;
        &--selected{
          outline-color: transparent;
          // border-color: ${p.theme.palette.brand.blue};
        }
      `
      : `
        &--selected{
          outline-color: ${p.theme.palette.brand.blue};
          border-color: ${p.theme.palette.brand.blue};
        }
    `}

    &::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 50%;
      background-color: ${p => p.theme.palette.grey[1]};
      transform: translate(-50%, -50%);
    }
    &--selected::after{
      animation: ${cursorAnimation} 1s infinite;
    }

    &--filled{
      &::after{
        opacity: 0;
      }
    }
  }
`;

const SpinnerBlue = styled.img.attrs({
  src: "../images/spinner_blue.svg",
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
