import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { iRootState } from "../store";
import styled, { css } from "styled-components";
import qs from "query-string";
import CWBLabsHeader from "./CWBLabs/components/Header";
import HeaderComponent from "./HeaderComponent";
import { setLanguage, bridgeAuthentication, seamlessAuthentication, getUser } from "../service/UserService";
// @ts-ignore
import { getCurUser, CWBLoadingCrest, setUserInfo } from "cwb-react";
// @ts-ignore
import { getSessionDataFromAuthToken } from "cwb-react";
import moment from "moment";
import {
  getCountryCode,
  getWebSessionKey,
  setCountryCode,
  setRegionCode,
  setTrackingData,
} from "../helpers";
import { ICountryDto } from "../shared/api/dtos/IReferencesDto";
import * as Sentry from "@sentry/react";
import { fallbackLoginRedirect } from "../helpers/redirect"
import { ITrialEligibibility } from "shared/api/dtos/IServicesDto";

interface Props extends RouteProps, StateProps, DispatchProps {
  isUpgrade?: boolean;
  isCompleted?: boolean;
  trialCheck?: boolean;
}

const SeamlessAuthRoute: React.FC<Props> = ({
  actorProfile,
  authenticatedUser,
  isUpgrade,
  trialCheck,
  isCompleted,
  sessionData,
  trialConditions,
  setAuthenticatedUser,
  setSessionData,
  getCountryList,
  getActorProfileCached,
  setRequesterCountryCode,
  setForceRequesterCountryCode,
  getTrialConditions,
  setTrialConditions,
  countryList,
  component: Component,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loginUpgradeRedirect = () => {
    const queryString = qs.stringify({
      ...qs.parse(rest.location.search),
      page: "upgrade",
    });
    window.location.replace(
      `${process.env.REACT_APP_CWB_Actor_Login}?${queryString}`
    );
    return <></>;
  };

  const upgradeToTrialRedirect = () => {
    if (trialConditions && (
      trialConditions.success || (trialConditions.services && trialConditions.services.length))
    ) {
      window.location.href =
        `${process.env.REACT_APP_CWB_Membership_Base}/promo?k=${getWebSessionKey()}`
        ;
      return true;
    }
    return false;
  }

  useEffect(() => {
    const loginProfileRedirect = () => {
      const queryString = qs.stringify({
        tk: sessionData.WebSessionKey,
        cid: authenticatedUser.id.userId,
        upgrade: "fail",
      });
      const homePath = `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`;
      window.top.location.href = homePath;
      return <></>;
    };
    const isInvalidActorProfile = () => {
      if (
        actorProfile &&
        authenticatedUser &&
        sessionData &&
        !actorProfile.servicesPlan.isFreemium &&
        !actorProfile.servicesPlan.isTrial &&
        !actorProfile.servicesPlan.canUpgrade &&
        !moment(actorProfile.servicesPlan.subscriptionEnd).isBefore(
          moment().add(1, "months")
        ) &&
        !moment(actorProfile.servicesPlan.renewalDate).isBefore(
          moment().add(1, "months")
        )
      ) {
        setIsLoading(false);
        if (!isCompleted) {
          loginProfileRedirect();
        }
      }
    };

    isInvalidActorProfile();
  }, [actorProfile, authenticatedUser, sessionData]);

  const getBestLocationCode = (payload: any) => {
    if (!payload)
      return '';

    if (payload.countryLocationCode)
      return payload.countryLocationCode;

    return payload.serviceLocationCode || '';
  }

  useEffect(() => {
    (async function attemptAuth() {
      try {
        if (!countryList || countryList.length === 0) {
          await getCountryList();
          return;
        }
        if (!authenticatedUser) {
          const o = qs.parse(rest.location.search);

          let authToken;
          if (o.k) {
            authToken = await bridgeAuthentication(o.k as string)
            if (!authToken) {
              authToken = await seamlessAuthentication();
            }
          } else {
            authToken = await seamlessAuthentication();
          }

          if (!authToken && isUpgrade) loginUpgradeRedirect();
          else if (!authToken) fallbackLoginRedirect();

          const tokenData = getSessionDataFromAuthToken(authToken);
          let user = getCurUser();
          if ((user.userId || user.id.userId) != (tokenData.UserId || tokenData.userId)) {
            setUserInfo(null);
            const payload = await getUser(authToken)
            if (payload && payload.data) {
              user = payload.data;
              setUserInfo(user);
            }
          }

          setSessionData(tokenData);

          if ((!user || user.id.userTypeId !== 8) && isUpgrade) {
            loginUpgradeRedirect();
          }
          else if (!user) {
            fallbackLoginRedirect();
            return;
          }

          if (user) {
            setAuthenticatedUser(user);
            const webSessionTargetLocationCode = getBestLocationCode(user);
            await setCountryAndRegion(user.countryId, webSessionTargetLocationCode);
          }

          setLanguage();
        } else {
          if (trialCheck) {
            var qsObj = qs.parse(window.location.search);
            if (qsObj.pp && qsObj.pp === "1") {
              const trialConditions = { success: false, notEligible: false } as ITrialEligibibility
              setTrialConditions(trialConditions);
            } else {
              const trialResult = await getTrialConditions();
              if (trialResult && (trialResult.success || (trialResult.services && trialResult.services.length))) {
                return;
              }
            }
          }

          if (authenticatedUser.id.userTypeId === 8) {
            await getActorProfileCached(authenticatedUser.id.userId);
          }

          const targetLocationCode = getBestLocationCode(authenticatedUser);
          await setCountryAndRegion(
            authenticatedUser.countryId,
            targetLocationCode
          );
          setLanguage();

          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        setIsLoading(false);
        if (!isCompleted) {
          if (isUpgrade) {
            setTimeout(() => loginUpgradeRedirect(), 1000);
          } else {
            setTimeout(() => fallbackLoginRedirect(), 1000);
          }
        }
      }
    })();
  }, [countryList, authenticatedUser]);

  const params = qs.parse(rest.location.search);
  const showHeader = !params.hs;
  // const isCWBLabs = params.cwblabs;
  const isCWBLabs = false;

  useEffect(() => {
    setTrackingData(params.src as string, rest.location.search);
  }, []);

  useEffect(() => {

    if (!trialConditions)
      return;

    upgradeToTrialRedirect();

  }, [trialConditions])

  const setBrowserCountry = async () => {
    try {
      if (!getCountryCode()) {
        if (params.dev && params.ctry) {
          await setForceRequesterCountryCode(params.ctry.toString())
        } else {
          await setRequesterCountryCode();
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const setCountryAndRegion = async (
    countryId: number,
    serviceLocationCode: string
  ) => {
    try {
      setRegionCode(serviceLocationCode);

      if (!countryId || !countryList || countryList.length === 0) {
        await setBrowserCountry();
        return;
      }
      const countryCode = getCountryCode();
      if (!countryCode) {
        const userCountry = countryList.find((country: ICountryDto) => {
          return country.countryId === countryId;
        });
        setCountryCode(userCountry.countryCode);
      }
    } catch (e) {
      Sentry.captureException(e);
      await setBrowserCountry();
    }
  };

  return (
    <>
      {showHeader && (isCWBLabs ? <CWBLabsHeader /> : <HeaderComponent />)}
      <Wrapper
        isCWBLabs={isCWBLabs}
        isLoading={isLoading}
        showHeader={showHeader}
      >
        {isLoading ? (
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        ) : (
          <Route component={Component} {...rest} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper: any = styled.div`
  display: flex;
  padding-top: 60px;

  ${(props: any) =>
    !props.showHeader &&
    css`
      padding-top: 0;
    `}

  ${(p: any) =>
    p.isCWBLabs &&
    css`
      background-color: #040f1c;
      padding-top: 80px;

      ${(p: any) =>
        p.isLoading &&
        css`
          padding-top: 80px;
          padding-bottom: calc(100vh - 80px - 138px);
        `}
    `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }

  @media all and (max-width: 768px) {
    padding-top: 0;
  }
`;

interface StateProps {
  actorProfile: any;
  authenticatedUser: any;
  sessionData: any;
  countryList: ICountryDto[];
  countryCode: string;
  trialConditions: ITrialEligibibility;
}

const mapStateToProps = (state: iRootState) => ({
  actorProfile: state.appModel.actorProfile,
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData,
  trialConditions: state.servicesModel.trialConditions,
  countryList: state.referencesModel.countryList,
  countryCode: state.referencesModel.countryCode,
});

interface DispatchProps {
  setAuthenticatedUser: (user: any) => any;
  getCountryList: () => any;
  getActorProfileCached: (clientId: number) => void;
  setSessionData: (sessionData: any) => any;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
  getTrialConditions: () => Promise<ITrialEligibibility>;
  setTrialConditions: (conditions: ITrialEligibibility) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: dispatch.appModel.setAuthenticatedUser,
  getCountryList: dispatch.referencesModel.getCountryList,
  getActorProfileCached: dispatch.appModel.getActorProfileCached,
  setSessionData: dispatch.appModel.setSessionData,
  setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
  setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
  getTrialConditions: dispatch.servicesModel.getTrialServicesConditions,
  setTrialConditions: dispatch.servicesModel.setTrialServicesConditions,
});

export default connect(mapStateToProps, mapDispatchToProps)(SeamlessAuthRoute);
