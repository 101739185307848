import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n } from "react-i18next";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled, { css } from "styled-components";
import BackButton from "../Common/BackButton";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import Tooltip from "../Common/Tooltip";
import PaymentToggle from "./PaymentToggle";
import {
  Section,
  SectionTitle,
  StyledInput,
  SmallTextError,
  StyledBlueBtn,
  SmallText,
  Spinner,
} from "../Common/StyledComponents";
import CWBLabsFooter from "components/CWBLabs/components/Footer";
import { iRootState } from "../../store";
import { ISubscriptionService, ITrialEligibibility } from "../../shared/api/dtos/IServicesDto";
import {
  IAutoDiscount,
  IDiscount,
  IDiscountWithClientId,
} from "../../shared/api/dtos/IPromoCodeDto";
import {
  IStripeResponse,
  IStripeCharge,
  ICreditCard,
  ISubscriptionResponse,
  IStripePaymentError,
  IStripePaymentForm,
} from "../../shared/api/dtos/IStripeDto";
import { isValidEmail, postalCodeValidation } from "../../helpers/validations";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { TrackingData } from "shared/api/dtos/ITrackingDataDto";
import EmailUpgradeModal from "components/Modals/EmailUpgradeModal";
import AgentFindModal from "components/Modals/AgentFindModal";
import {
  findTrackingData,
  getSessionId,
  getWebSessionKey,
  timedSentrySendThenErrorPage,
  toFixedTrunc,
} from "helpers";
import grungeEffect from "../../images/grunge.png";
// @ts-ignore
import { getCurUser, CWBLoadingCrest } from "cwb-react";


interface IProps extends StateProps, DispatchProps, RouteComponentProps {
  t?: any;
  stripe?: any;
  country: string;
  provinceId: number;
  allowSkip?: boolean;
  onSubmit: (apiPayload: IStripeCharge) => void;
  getServices: () => void;
  moveToNext: (serviceId: number) => void;
  onSkip?: () => void;
  clientId?: number;
  promoCode?: any;
  monthlyDefault?: boolean;
  actorProfile: any;
  trialConditions: ITrialEligibibility;
}

interface IState {
  fields: any;
  subtotal: string;
  tax: string;
  total: string;
  discountMessage: string;
  discount: any;
  currencySymbol: string;
  discountApplied: boolean;
  discountAmount: Number;
  discountInputDisabled: boolean;
  voiceOnly: boolean;
  isEmailModalOpen: boolean;
  skipped: boolean;
  hideMembershipToggle: boolean;
  isAgentFindModalOpen: boolean;
  postCurrencySymbol?: string;
}

class Membership extends React.Component<IProps, IState> {
  state: IState = {
    fields: {
      name: "",
      postalCode: "",
      serviceId: 0,
      couponCode: "",
      addressLine1: "",
    } as IStripePaymentForm,
    subtotal: "0.00",
    tax: "0.00",
    total: "0.00",
    discountMessage: "",
    discount: null,
    currencySymbol: "$",
    discountApplied: false,
    discountAmount: 0,
    discountInputDisabled: false,
    voiceOnly: false,
    isEmailModalOpen: false,
    isAgentFindModalOpen: false,
    skipped: false,
    hideMembershipToggle: true,
    postCurrencySymbol: ''
  };

  async componentDidMount() {
    this.props.setGlobalLoading(true);
    try {
      await this.props.getServices()
      
      this.setService();
      if (this.props.promoCode) {
        await this.applyPromoCode(this.props.promoCode);
      } else if (this.props.clientId > 0) {
        const autoPromoCode = await this.props.tryFindPromoCodes(this.props.clientId);
        if (autoPromoCode && autoPromoCode.isValid && autoPromoCode.code) {
          await this.applyPromoCode(autoPromoCode.code)
        }
      }
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
    finally {
      this.props.setGlobalLoading(false);
    }

    try {
      const qsArgs = qs.parse(this.props.location.search);
      const isJob = qsArgs.page === 'openbd'
      const bdText = qsArgs.bd ? qsArgs.bd.toString() : '';
      const bdType = bdText ? bdText[0] : null
      if (isJob && bdText && bdType !== "2") {
        const bdId = bdText.substring(1);
        const info = { bdId, bdType };
        if (!await this.props.isAgentFindBreakdown(info)) {
          this.setState(a => ({ ...a, hideMembershipToggle: false }))
        } else {
          if ((!this.props.clientId || this.props.clientId <= 0) || !this.isUpgrade()) {
            this.setState(a => ({ ...a, isAgentFindModalOpen: true }));
          }
        }
      } else {
        this.setState(a => ({ ...a, hideMembershipToggle: false }))
      }
    } catch (e) {
      this.setState(a => ({ ...a, hideMembershipToggle: false }))
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (
      props.serviceList !== state.serviceList ||
      props.subscriptionDetails !== state.subscriptionDetails
    ) {
      return {
        serviceList: props.serviceList,
        subscriptionDetails: props.subscriptionDetails,
      };
    }
    return null;
  }

  applyPromoCode = async (promoCode: string) => {
    let fields = this.state.fields;
    fields["couponCode"] = promoCode;
    this.setState({ fields });

    const discountWithClientId: IDiscountWithClientId = {
      code: promoCode,
      clientId: this.props.clientId,
    };

    if (this.props.clientId) {
      const discountResponse = await this.props.getDiscountByClientId(
        discountWithClientId
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", {
              code: promoCode,
            })
            : this.props.t("Invalid code: {{code}}", {
              code: promoCode,
            }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    } else {
      const discountResponse = await this.props.getDiscount(
        promoCode
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", {
              code: promoCode,
            })
            : this.props.t("Invalid code: {{code}}", {
              code: promoCode,
            }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    }
  };

  setService = () => {
    let fields = this.state.fields;
    const serviceCount = this.props.serviceList.length;
    if (serviceCount === 1) {
      const annualService = this.props.serviceList.find(
        (x) => x.durationMonths === 12
      );
      if (annualService) {
        fields.serviceId = annualService.serviceId;
        const total = this.getTotal(annualService);
        this.setState({ fields, voiceOnly: true });
        if (annualService.currency === "EUR")
          this.setState({ currencySymbol: "€" });
        if (annualService.countryCode === "MX")
          this.setState({ postCurrencySymbol: annualService.currency });
        this.updatePrice(
          this.getSubTotal(annualService),
          this.getTax(annualService.price, annualService.tax).toFixed(2),
          total
        );
      }
    } else {
      const annualService = this.props.serviceList.find(
        (x) => x.durationMonths === 12
      );
      const monthService = this.props.serviceList.find(
        (x) => x.durationMonths === 1
      );
      if (this.props.monthlyDefault) {
        if (monthService) {
          fields.serviceId = monthService.serviceId;
          const total = this.getTotal(monthService);
          this.setState({ fields });
          if (monthService.currency === "EUR")
            this.setState({ currencySymbol: "€" });
          if (monthService.countryCode === "MX")
            this.setState({ postCurrencySymbol: annualService.currency });
          this.updatePrice(
            this.getSubTotal(monthService),
            this.getTax(monthService.price, monthService.tax).toFixed(2),
            total
          );
        }
      } else {
        if (annualService) {
          fields.serviceId = annualService.serviceId;
          const total = this.getTotal(annualService);
          this.setState({ fields });
          if (annualService.currency === "EUR")
            this.setState({ currencySymbol: "€" });
          if (annualService.countryCode === "MX")
            this.setState({ postCurrencySymbol: annualService.currency });
          this.updatePrice(
            this.getSubTotal(annualService),
            this.getTax(annualService.price, annualService.tax).toFixed(2),
            total
          );
        }
      }
    }
  };

  getMonthlyPrice = (
    service: ISubscriptionService,
    discount: IDiscount
  ): string => {
    let price = 0;
    if (service.price <= 1) price = service.price;

    let cost = service.price;
    if (discount && discount.isValid) {
      let discountedTotal = this.getDiscountedTotalWithoutTax(
        discount,
        service
      );
      let toNumber = discountedTotal ? Number(discountedTotal) : cost;
      cost = toNumber;
    }

    price = Number(cost / service.durationMonths);
    price = toFixedTrunc(price, 2);
    if (this.state.currencySymbol === "€") {
      return price.toString().replace(".", ",");
    } else {
      return price.toString();
    }
  };

  getYearlyPrice = (service: ISubscriptionService): number => {
    if (service.price <= 1) return service.price;
    if (service.durationMonths === 12 && this.state.discount) {
      if (this.state.discount.isDiscountPercent) {
        let discountAmount = (service.price / 100) * this.state.discount.discountAmount;
        let newPrice = service.price - discountAmount;
        return Number(newPrice);
      } else {
        return Number(service.price - this.state.discount.discountAmount);
      }
    }
    return service.price;
  };

  getSavingPerYear = (service: ISubscriptionService) => {
    if (!this.props.serviceList) return null;

    let serviceList = this.props.serviceList.map((service) => ({
      ...service,
      totalPerMonth: service.price / service.durationMonths,
    }));

    const max = Math.max.apply(
      Math,
      serviceList.map(function (o) {
        return o.totalPerMonth;
      })
    );

    const serviceWithTotal = serviceList.find(
      (x) => x.serviceId === service.serviceId
    );

    if (max <= serviceWithTotal.totalPerMonth) return null;

    return ((max - serviceWithTotal.totalPerMonth) * 12).toFixed(2);
  };

  getTax = (price: number, tax: number): number => {
    if (!price || !tax) return 0;

    return (price * tax) / 100;
  };

  getSubTotal = (service: ISubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    return service.price.toFixed(2);
  };

  getTotal = (service: ISubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    const tax = this.getTax(service.price, service.tax);

    return (service.price + tax).toFixed(2);
  };

  getTagDiscount = (services: ISubscriptionService[]) => {
    const yearlyService = services.find((item) => item.durationMonths === 12);
    const monthlyService = services.find((item) => item.durationMonths === 1);
    if (yearlyService && monthlyService) {
      const yearlyPrice = this.getYearlyPrice(yearlyService);
      const totalMonthlyPrice = monthlyService.price * 12;
      const offPerent = ((1 - yearlyPrice / totalMonthlyPrice) * 100).toFixed(
        1
      );
      return offPerent;
    } else {
      return null;
    }
  };

  getDiscountedTotal = (
    coupon: IDiscount,
    service: ISubscriptionService
  ): string => {
    if (!coupon || !service) return service.price.toFixed(2);

    let price = service.price;

    let discountAmount = coupon.discountAmount;

    if (coupon.isDiscountPercent)
      discountAmount = (price / 100) * coupon.discountAmount;

    let newPrice = price - discountAmount;
    const tax = this.getTax(newPrice, service.tax);
    const total = (newPrice + tax).toFixed(2);

    this.updatePrice(price.toFixed(2), tax.toFixed(2), total);
    this.setState({
      discountAmount,
    });
    return total;
  };

  getDiscountedTotalWithoutTax = (
    coupon: IDiscount,
    service: ISubscriptionService
  ): string => {
    if (!coupon || !service) return service.price.toFixed(2);

    let price = service.price;

    let discountAmount = coupon.discountAmount;

    if (coupon.isDiscountPercent)
      discountAmount = (price / 100) * coupon.discountAmount;

    let newPrice = price - discountAmount;

    return newPrice.toFixed(2);
  };

  getDiscountedPercent = (): string => {
    const { discount } = this.state;
    const service = this.props.serviceList.find(
      (x) => x.serviceId === this.state.fields.serviceId
    );
    if (!discount || !service) return "";

    if (discount.isDiscountPercent) return discount.discountAmount.toFixed(0);

    let price = service.price;
    let discountAmount = discount.discountAmount;
    return ((discountAmount / price) * 100).toFixed(0);
  };

  setTotal = () => {
    const { discount } = this.state;
    const service = this.props.serviceList.find(
      (x) => x.serviceId === this.state.fields.serviceId
    );
    if (!service)
      return;

    const isYearly = service.durationMonths === 12;
    let total = "";

    if (discount && discount.isValid && isYearly) {
      const { code } = discount;
      const message = this.props.t("Valid code: {{code}}", { code });
      this.setState({
        discountMessage: message,
        discountApplied: true,
      });
      this.getDiscountedTotal(discount, service);
    } else if (discount && discount.isValid && !isYearly) {
      const { code } = discount;
      const message = this.props.t(
        "Valid code: {{code}} only applies to yearly service",
        { code }
      );
      this.setState({
        discountMessage: message,
        discountApplied: false,
      });
      total = this.getTotal(service);
      this.updatePrice(
        this.getSubTotal(service),
        this.getTax(service.price, service.tax).toFixed(2),
        total
      );
    } else {
      total = this.getTotal(service);
      this.setState({
        discountApplied: false,
      });
      this.updatePrice(
        this.getSubTotal(service),
        this.getTax(service.price, service.tax).toFixed(2),
        total
      );
    }
  };

  updatePrice = (subtotal: string, tax: string, total: string) => {
    this.setState({ total });
    this.setState({ tax });
    this.setState({ subtotal });
  };

  handleServiceChange = (serviceId: number) => {
    this.setState({ fields: { ...this.state.fields, serviceId } }, () => {
      this.setTotal();
    });
  };

  handleChangeService = () => {
    const services: ISubscriptionService[] = this.props.serviceList;
    const currentServiceId = this.state.fields.serviceId;
    const newSelectedService = services.find(
      (item) => item.serviceId !== currentServiceId
    );
    const newServiceId = newSelectedService.serviceId;
    this.setState(
      { fields: { ...this.state.fields, serviceId: newServiceId } },
      () => {
        this.setTotal();
      }
    );
  };

  handleChange = async (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    if (e.target.name === "couponCode") this.setState({ discountMessage: "" });
    // if (e.target.name === "postalCode") {
    //   await this.handleOnBlurPostal(e);
    // }
  };

  // handleOnBlurPostal = async (e: any) => {
  //   if (!e || !e.target || !e.target.value) return;
  //   await this.getService(e.target.value);
  //   this.setTotal();
  // };

  handleDiscountClick = async (e: any) => {
    if (!this.state.fields.couponCode) return;
    const inputCode = this.state.fields.couponCode;
    if (this.props.clientId) {
      const discountWithClientId: IDiscountWithClientId = {
        code: inputCode,
        clientId: this.props.clientId,
      };
      const discountResponse = await this.props.getDiscountByClientId(
        discountWithClientId
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", { code: inputCode })
            : this.props.t("Invalid code: {{code}}", { code: inputCode }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    } else {
      const discountResponse = await this.props.getDiscount(inputCode);
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", { code: inputCode })
            : this.props.t("Invalid code: {{code}}", { code: inputCode }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    }
  };

  cancelDiscountClick = async (e: any) => {
    const currentCoupon = this.state.fields.couponCode;
    if (!currentCoupon)
      return;

    this.setState(
      {
        discount: null,
        fields: { ...this.state.fields, couponCode: "" },
        discountMessage: "",
        discountApplied: false,
        discountAmount: 0,
        discountInputDisabled: false,
      },
      () => {
        this.setTotal();
      }
    );
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IStripePaymentForm = this.state.fields;
    let errors = {} as IStripePaymentError;

    if (!fields.name) errors.name = t("Name on Card field is required");

    if (!fields.addressLine1) errors.addressLine1 = t("Address is required");

    if (!fields.postalCode)
      errors.addressZip = t("Postal / Zip Code field is required");

    const validationError = postalCodeValidation(
      fields.postalCode.replace(/\s/g, ""),
      t
    );
    if (validationError) errors.addressZip = validationError;

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  isUpgrade = () => {
    return this.props.actorProfile;
  };

  hasValidActorProfileEmail = () => {
    const actorProfile = this.props.actorProfile;
    if (actorProfile && actorProfile.email && isValidEmail(actorProfile.email))
      return true;

    this.setState({ ...this.state, isEmailModalOpen: true });
    return false;
  };

  handleCloseEmailModalOpen = () => {
    this.setState({ ...this.state, isEmailModalOpen: false });
  };

  handleCloseAgentFindModalOpen = () => {
    this.setState({ ...this.state, isAgentFindModalOpen: false });
  };

  handleSubmit = async () => {
    if (this.props.isLoading || !this.checkValidation()) return;
    let fields = this.state.fields;

    if (this.isUpgrade() && !this.hasValidActorProfileEmail()) return;

    try {
      this.props.setIsLoading(true);
      let { token, error } = await this.props.stripe.createToken();

      if (error && error.message) {
        let errors = {} as IStripePaymentError;
        // TODO: how to translate erorr message? check i18 doc
        errors.errorMessage = error.message;
        this.props.setErrors(errors);
        this.props.setIsLoading(false);
        return;
      }

      const stripeResponse: IStripeResponse = token;

      const apiPayload = {} as IStripeCharge;
      apiPayload.token = stripeResponse.id;

      apiPayload.card = {} as ICreditCard;
      apiPayload.card.brand = stripeResponse.card.brand;
      apiPayload.card.expMonth = stripeResponse.card.exp_month;
      apiPayload.card.expYear = stripeResponse.card.exp_year;
      apiPayload.card.last4 = stripeResponse.card.last4;

      apiPayload.card.name = fields.name;
      apiPayload.card.addressZip = fields.postalCode.replace(/\s/g, "");
      apiPayload.card.addressLine1 = fields.addressLine1;
      apiPayload.couponCode = fields.couponCode;
      apiPayload.serviceId = fields.serviceId;
      apiPayload.provinceId = this.props.provinceId;

      apiPayload.trackingData = findTrackingData(this.props.location.search);
      await this.props.onSubmit(apiPayload);

      this.moveToNext(apiPayload.serviceId);
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  };

  moveToNext = (serviceId: number) => {
    if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.moveToNext(serviceId);
    }
  };

  handleSkip = () => {
    if (this.state.skipped) {
      return;
    }

    this.setState({ ...this.state, skipped: true });
    this.props.onSkip();
  };

  parseCurrency = (price: number): string => {
    if (this.state.currencySymbol === "€") {
      return price.toString().replace(".", ",");
    } else {
      return price.toString();
    }
  };

  parseCurrencyString = (price: string): string => {
    if (this.state.currencySymbol === "€") {
      return price.replace(".", ",");
    } else {
      return price;
    }
  };
  render() {
    const { t, location } = this.props;
    const errors: IStripePaymentError = this.props.errors;
    const fields: IStripePaymentForm = this.state.fields;
    const services: ISubscriptionService[] = this.props.serviceList;
    const currencySymbol = this.state.currencySymbol;


    const isTablet = window.matchMedia("(max-width: 768px)").matches;
    // const isCWBLabs = qs.parse(location.search).cwblabs;
    const isCWBLabs = false;

    let messageColor = "";

    if (
      this.state.discount &&
      this.state.discount.isValid &&
      this.state.discountApplied
    ) {
      messageColor = "light-green";
    } else if (this.state.discount && this.state.discount.isValid) {
      messageColor = "orange";
    } else if (this.state.discount && !this.state.discount.isValid) {
      messageColor = "red";
    }

    const selectedService = services?.find(
      (item) => item.serviceId === this.state.fields.serviceId
    );
    const monthlyPrice = selectedService
      ? this.getMonthlyPrice(selectedService, this.state.discount)
      : 0;
    const offPercent = this.getTagDiscount(services);

    const discountPercent = this.getDiscountedPercent();

    return (
      <StyledPageContainer isCWBLabs={isCWBLabs}>
        <BackButton width={isTablet ? 690 : 880} />
        <Wrapper isCWBLabs={isCWBLabs}>
          <StyledTitle>{t("Start your Membership")}</StyledTitle>
          <RowColumnWrapper>
            <Row>
              <ColumnBilling>
                <BillingWrapper>
                  <Section className="margin-bottom-none">
                    <SectionLargeTitle>{t("Billing")}</SectionLargeTitle>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Name on card")}</SectionTitle>
                    <StyledInput
                      name="name"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.name}
                    />
                    <SmallTextError className="error">
                      <span>{errors.name}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <CardElement
                      hidePostalCode={true}
                      className="form-control"
                    />
                    <SmallTextError className="error">
                      <span>{errors.errorMessage}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Billing Address")}</SectionTitle>
                    <StyledInput
                      name="addressLine1"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.addressLine1}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressLine1}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {t("Billing Postal / Zip Code")}
                    </SectionTitle>
                    <StyledInput
                      name="postalCode"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.postalCode}
                    // onBlur={this.handleOnBlurPostal}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressZip}</span>
                    </SmallTextError>
                  </Section>
                  <InfoText>
                    {t(
                      `By completing this purchase, I will enter into Agreement with Casting Workbook Services, Inc. ("Casting Workbook"). For the price of my enrolment, my photograph(s) and other information will be placed online in Casting Workbook, on my agent's roster (if represented) or the Talent Scout database (if unrepresented). I understand that my photograph(s) and other portfolio materials will be made available to casting director members and talent agent members (where applicable) for the duration of my membership.`
                    )}
                  </InfoText>
                  <InfoText>
                    {t(
                      `I understand and agree that Casting Workbook makes no guarantee or promise, either expressed or implied, that I will obtain work, but only that my photograph(s) and other information supplied by myself will be made available to industry professionals utilizing the Casting Workbook database.`
                    )}
                  </InfoText>
                  <Section>
                    <StyledBlueBtn
                      disabled={this.props.isLoading || this.props.isGlobalLoading}
                      onClick={this.handleSubmit}
                    >
                      {t("Complete Purchase")}
                      {this.props.isLoading && (
                        <Spinner
                          src="../images/spinner.svg"
                          className="spinner-width"
                        />
                      )}
                    </StyledBlueBtn>
                  </Section>
                </BillingWrapper>
              </ColumnBilling>
              {this.props.isGlobalLoading ? <ColumnMembership>
                <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
              </ColumnMembership>
                :
                (<ColumnMembership>
                  <MembershipContainer>
                    <MembershipWrapper>
                      <Section className="margin-bottom-none">
                        <SectionLargeTitle>
                          {t("Actor Membership")}
                        </SectionLargeTitle>
                      </Section>
                      <Section>
                        {!this.state.voiceOnly && (
                          <ServiceSelection>
                            {!this.state.hideMembershipToggle || this.props.monthlyDefault ?
                              <Selection>{t("Annual")}</Selection> :
                              <></>
                            }
                            <PaymentToggle
                              checked={
                                selectedService &&
                                selectedService.durationMonths === 12
                              }
                              hideMembershipToggle={this.state.hideMembershipToggle && !this.props.monthlyDefault}
                              onChange={this.handleChangeService}
                            />
                            {!this.state.hideMembershipToggle || this.props.monthlyDefault ?
                              <Selection>{t("Monthly")}</Selection> :
                              <></>
                            }
                          </ServiceSelection>
                        )}
                        {selectedService &&
                          selectedService.durationMonths === 12 && (
                            <PriceBox>
                              <div>
                                <PriceTitle>{t("ANNUAL PLAN")}</PriceTitle>
                                {/* {offPercent && !this.state.discountApplied && (
                                <PriceDiscount>
                                  {t("{{offPercent}}% OFF", { offPercent })}
                                </PriceDiscount>
                              )} */}
                              </div>
                              <PriceTotal>
                                {currencySymbol}
                                {this.getMonthlyPrice(
                                  selectedService,
                                  this.state.discount
                                )}
                                <PriceTotalSub>{t("/month")}</PriceTotalSub>
                              </PriceTotal>

                              <PriceBill>
                                <b>({t("billed annually")})</b>
                              </PriceBill>

                              {this.state.discountApplied && (
                                <PriceBill>{t("One time discount")}</PriceBill>
                              )}
                            </PriceBox>
                          )}
                        {selectedService && selectedService.durationMonths === 1 && (
                          <PriceBox>
                            <PriceTitle>{t("MONTHLY PLAN")}</PriceTitle>
                            <PriceTotal>
                              {currencySymbol}
                              {this.parseCurrencyString(toFixedTrunc(selectedService.price, 2))}
                              <PriceTotalSub>{t("/month")}</PriceTotalSub>
                            </PriceTotal>
                            <PriceBill>
                              <b>({t("billed monthly")})</b>
                            </PriceBill>
                          </PriceBox>
                        )}
                        <PriceTip>
                          {selectedService && selectedService.durationMonths === 1 && (
                            <>{t("*Recurring billing. Cancel anytime")}</>
                          )}
                          {!selectedService || selectedService.durationMonths !== 1 && (
                            <>{t("*Recurring billing.")}</>
                          )}
                        </PriceTip>
                      </Section>
                      {this.state.discountApplied && discountPercent && (
                        <FloatingDiv>
                          <DiscountStamp>
                            {discountPercent}
                            {"%   " + t("OFF")}
                          </DiscountStamp>
                        </FloatingDiv>
                      )}
                    </MembershipWrapper>
                    <Divider />
                    <MembershipWrapper>
                      <Section>
                        <SectionTitle>{t("Discount Code")}</SectionTitle>
                        <WrapperDiscount>
                          <InputDiscount
                            value={this.state.fields.couponCode}
                            name="couponCode"
                            onChange={this.handleChange}
                            disabled={this.state.discountInputDisabled}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                this.handleDiscountClick(e);
                              }
                            }}
                          />
                          {!this.state.discountInputDisabled ? (
                            <ButtonDiscount onClick={this.handleDiscountClick}>
                              {t("Apply")}
                            </ButtonDiscount>
                          ) : (
                            <ButtonDiscount onClick={this.cancelDiscountClick}>
                              {t("Cancel")}
                            </ButtonDiscount>
                          )}
                        </WrapperDiscount>
                      </Section>
                      <Section>
                        <SmallText>
                          <span
                            className={
                              /* this.state.fields.couponCode ? "light-green" : "red" */
                              messageColor
                            }
                          >
                            {" "}
                            {this.state.discountMessage}
                          </span>
                        </SmallText>
                      </Section>
                    </MembershipWrapper>
                    <Divider />
                    <MembershipWrapper>
                      <PriceTextWrapper>
                        <PriceTextBox>
                          <PriceTextLeft>{t("Subtotal")}</PriceTextLeft>
                          <PriceTextRight>{`${currencySymbol}${this.parseCurrencyString(
                            this.state.subtotal
                          )}`} {this.state.postCurrencySymbol}</PriceTextRight>
                          <PriceTextClear></PriceTextClear>
                        </PriceTextBox>
                      </PriceTextWrapper>
                    </MembershipWrapper>
                    <Divider />
                    {this.state.discountApplied && (
                      <>
                        <MembershipWrapper>
                          <PriceTextWrapper>
                            <PriceTextBox>
                              <PriceTextLeft>{t("Discount")}</PriceTextLeft>
                              <PriceTextRight>{`${currencySymbol}${this.parseCurrencyString(
                                this.state.discountAmount.toFixed(2)
                              )}`} {this.state.postCurrencySymbol}</PriceTextRight>
                              <PriceTextClear></PriceTextClear>
                            </PriceTextBox>
                          </PriceTextWrapper>
                        </MembershipWrapper>
                        <Divider />
                      </>
                    )}
                    <MembershipWrapper>
                      <PriceTextWrapper>
                        <PriceTextBox>
                          <PriceTextLeft>{t("Tax")}</PriceTextLeft>
                          <PriceTextRight>{`${currencySymbol}${this.parseCurrencyString(
                            this.state.tax
                          )}`} {this.state.postCurrencySymbol} </PriceTextRight>
                          <PriceTextClear></PriceTextClear>
                        </PriceTextBox>
                      </PriceTextWrapper>
                    </MembershipWrapper>
                    <DueTodayDivider />
                    <DueTodayWrapper>
                      <PriceTextWrapper>
                        <PriceTextBox>
                          <PriceTextLeft>{t("Due Today")}</PriceTextLeft>
                          <PriceTextRight>{`${currencySymbol}${this.parseCurrencyString(
                            this.state.total
                          )}`} {this.state.postCurrencySymbol}</PriceTextRight>
                          <PriceTextClear></PriceTextClear>
                        </PriceTextBox>
                      </PriceTextWrapper>
                    </DueTodayWrapper>
                  </MembershipContainer>
                  {this.props.allowSkip && (
                    <StyledLink skipped={this.state.skipped} onClick={this.handleSkip}>
                      {t("Skip for now - get started for free")}
                    </StyledLink>
                  )}
                </ColumnMembership>)}
            </Row>
          </RowColumnWrapper>
        </Wrapper>
        {this.state.isEmailModalOpen && (
          <EmailUpgradeModal onClose={() => this.handleCloseEmailModalOpen()} />
        )}
        {this.state.isAgentFindModalOpen && (
          <AgentFindModal open={this.state.isAgentFindModalOpen} isLoading={this.props.isLoading} onClose={() => this.handleCloseAgentFindModalOpen()} />
        )}
        {isCWBLabs ? <StyledCWBLabsFooter /> : <Footer />}
      </StyledPageContainer>
    );
  }
}

interface StateProps {
  serviceList: ISubscriptionService[];
  subscriptionDetails: ISubscriptionResponse;
  errors: IStripePaymentError;
  isLoading: boolean;
  actorProfile: any;
  isGlobalLoading: boolean;
  trialConditions: ITrialEligibibility;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    serviceList: state.servicesModel.services,
    subscriptionDetails: state.stripeModel.subscriptionDetails,
    errors: state.stripeModel.errors,
    isLoading: state.stripeModel.isLoading,
    actorProfile: state.appModel.actorProfile,
    isGlobalLoading: state.appModel.loading,
    trialConditions: state.servicesModel.trialConditions
  };
}

interface DispatchProps {
  getDiscount: (code: string) => Promise<IDiscount>;
  createStripeSubscription: (dto: IStripeCharge) => void;
  setErrors: (errors: IStripePaymentError) => void;
  setIsLoading: (loading: boolean) => any;
  getDiscountByClientId: (
    discountWithClientId: IDiscountWithClientId
  ) => Promise<IDiscount>;
  isAgentFindBreakdown: (bdInfo: any) => Promise<boolean>;
  tryFindPromoCodes: (clientId: number) => Promise<IAutoDiscount>;
  setGlobalLoading: (loading: boolean) => any;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    getDiscountByClientId: dispatch.promoCodeModel.getDiscountByClientId,
    getDiscount: dispatch.promoCodeModel.getDiscount,
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    setErrors: dispatch.stripeModel.setErrors,
    setIsLoading: dispatch.stripeModel.setIsLoading,
    isAgentFindBreakdown: dispatch.servicesModel.isAgentFindBreakdown,
    tryFindPromoCodes: dispatch.promoCodeModel.tryFindPromoCodes,
    setGlobalLoading: dispatch.appModel.setLoading,
  };
}

const StyledPageContainer = styled(PageContainer)`
  ${(p) =>
    p.isCWBLabs &&
    css`
      background-color: #040f1c;

      @media all and (max-width: 520px) {
        padding-top: 8px;
        border: none;

        & > div:first-of-type {
          display: none;
        }
      }
    `}
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  width: 880px;
  padding: 40px 60px;

  @media all and (max-width: 768px) {
    width: 690px;
    margin: 0;
    padding: 24px 24px 40px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    margin: 0;
    padding: 24px 16px 40px;

    ${(p) =>
    p.isCWBLabs &&
    css`
        width: 100%;
        min-width: 40%;
        padding: 24px 16px 40px
        border-top: 0;
        border-radius: 0;
      `}
  }
`;

const RowColumnWrapper = styled.div`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media all and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

const ColumnBilling = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 12px;
  min-width: 420px;
  background-color: #f2f2f7;
  border-radius: 4px;
  padding: 8px 40px 8px;
  margin-top: 24px;
  margin-right: 3%;
  height: fit-content;

  @media all and (max-width: 768px) {
    min-width: 360px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    min-width: unset;
    margin: 16px 0;
    padding: 12px 16px;
  }
`;

const ColumnMembership = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100%;
  justify-content: start;
  // align-items: center;
  background-color: ${(p) => p.theme.white};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-top: 24px;
  height: 100%;
`;

const MembershipContainer = styled.div`
  border: ${(props) => props.theme.dropDown["border"]} !important;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const SectionLargeTitle = styled.div`
  color: #122640;
  font-weight: ${(p) => p.theme["font-weight-600"]};
  text-align: center;
`;

const BillingWrapper = styled.div``;

const MembershipWrapper = styled.div`
  padding: 8px 40px 18px;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DueTodayWrapper = styled.div`
  padding: 8px 40px 18px;
  background-color: #f2f2f7;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfoText = styled.div`
  margin-top: 24px;
  font-size: 11px !important;
`;

const PriceTextWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 7px 0 0 0;
`;

const PriceTextBox = styled.div`
  overflow: auto;
  margin: 0;
`;

const PriceTextLeft = styled.div`
  float: left;
`;

const PriceTextRight = styled.div`
  float: right;
`;

const PriceTextClear = styled.div`
  clear: both;
`;

const WrapperDiscount = styled.div`
  margin-top: 10px;
  display: flex;
`;

const InputDiscount = styled.input`
  padding: 6px;
  font-size: 15px;
  border: 1px solid grey;
  float: left;
  flex: 1;
  border: 1px solid #d3dde9;
  border-right: none;
  border-radius: 4px 0 0 4px;
`;

const ButtonDiscount = styled.button`
  float: left;
  width: auto;
  padding: 6px 12px;
  background: white;
  color: #2196f3 !important;
  font-size: 15px;
  /* border: 1px solid grey; */
  /* border-left: none; */
  cursor: pointer;
  border: 1px solid #2196f3 !important;
  border-radius: 0 4px 4px 0;
  border-left: none;
`;

const Divider = styled.div`
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const DueTodayDivider = styled.div`
  margin: 8px 0 0 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const ServiceSelection = styled.div`
  margin: 16px auto;
  display: flex;
  width: 150px;
  justify-content: center;
`;

const Selection = styled.div`
  font-size: ${(p) => p.theme["xs-font-size"]};
  line-height: 18px;
`;
interface LinkProps {
  skipped: boolean
}

const StyledLink = styled.a<LinkProps>`
  && {
    color: ${(p) => p.theme.color};
    margin: 8px auto 0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    cursor: pointer;
    opacity: ${(p) => p.skipped ? '0.4' : 1};
    ${(p) => p.skipped ? 'pointer-events: none;' : ''};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PriceBox = styled.div`
  background: rgba(46, 210, 255, 0.05);
  /* Brand/Med-Blue */
  border: 1px solid #00aaff;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 140px;
  width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
`;

const PriceTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const PriceTotalSub = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const PriceTotal = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  text-align: center;
  margin: 8px 0px 10px;
`;

const PriceTotalMonth = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  text-align: center;
  margin: 16px 0px 20px;
`;

const PriceBill = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;
`;

const PriceTip = styled.div`
  font-size: 9px;
  line-height: 18px;
  /* identical to box height, or 225% */

  text-align: center;
  color: #53627c;
  margin-top: 4px;
`;

const PriceTag = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  /* identical to box height */
  text-align: center;

  color: #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  top: -55px;
  left: -50px;
  width: 92px;
  height: 92px;
  border-width: 11px;
  border-style: solid;
  border-color: transparent transparent #ff1870 transparent;
  padding-top: 80px;
`;

const PriceDiscount = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;
  color: #ff1870;
`;

const StyledCWBLabsFooter = styled(CWBLabsFooter)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const FloatingDiv = styled.div`
  position:absolute;
`

const DiscountStamp = styled.span`
  color: #555;
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0 10px 0 10px;
  text-transform: uppercase;
  border-radius: 10px;
  font-family: "Courier";
  -webkit-mask-image: url(${grungeEffect});
  -webkit-mask-size: 944px 604px;

  color: #d23;
  border: 6px solid #d23;
  transform: rotate(20deg);
  -webkit-mask-position: 2rem 3rem;
  font-size: 16px;
  position: relative;
  top: -165px;
  left: 184px;
  margin: -16px;
`;

export default withI18n()(
  injectStripe(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Membership))
  )
);
