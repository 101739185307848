import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
// @ts-ignore
import { CWBDropdown, CWBCheckbox } from "cwb-react";
import {
  NameMaxLength,
  getSessionId,
  getCurUserSignUp,
  getCountryCode,
  setRegionCode,
  getWebSessionKey,
  errorPageRedirect,
} from "../../../helpers";
import BirthDatePicker from "../../Common/BirthDatePicker";
import Button from "../../Common/Button";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import Tooltip from "../../Common/Tooltip";
import Typography from "../../Common/Typography";
import {
  Section,
  SectionTitle,
  StyledInput,
  SmallTextError,
  Spinner,
  CheckboxWrapperStage,
  StyledCheckbox,
  LargeText,
  ErrorText,
} from "../../Common/StyledComponents";
import CWBLabsActorStageNameStep from "components/CWBLabs/ActorStageNameStep";
import { BlendedStyledInput } from "components/Common";
import {
  IActorStageName,
  IActorError,
  IActorFilmCenter,
} from "../../../shared/api/dtos/IActorDto";
import { iRootState } from "../../../store";
import { nameRegex } from "helpers/validations";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { IGroupedCountry } from "helpers/ICountryList";
// @ts-ignore
import moment from "moment";
import { ISignupAccountDto, ISignupResidence } from "shared/api/dtos/ISignupDto";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  ageChecked: boolean;

  cityList: any[];

  isStageNameChanged: boolean;
  isResidenceChanged: boolean;
}

class ActorStageNameStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      firstName: "",
      lastName: "",
      middleName: "",
      birthDate: null,

      country: "",
      province: "",
      provinceId: 1,
      city: "",
      cityId: 1,
      countryId: 1,
      sendBreakdowns: true,
      representedActor: false,
      unionActor: false,
      memberId: "",
      onCamera: true,
      voice: true,
    },
    isFormSubmitClicked: false,
    ageChecked: false,
    cityList: [],

    isStageNameChanged: false,
    isResidenceChanged: false,
  };

  async componentDidMount() {
    const user: ISignupAccountDto = getCurUserSignUp();

    if (getSessionId() && user) {
      if (this.props.groupCountryList.length <= 0)
        if (this.props.groupCountryList.length <= 0)
          await this.props.getGroupCountryList();

      if (user.signupResidence) this.mapUserToStateFields(user.signupResidence);
      else {
        try {
          const grpCountries = this.props.groupCountryList;
          let country = grpCountries[0];

          let countryCode = getCountryCode()?.toUpperCase();
          if (!countryCode || countryCode == "") countryCode = "CA";

          country = grpCountries.find(
            (x) => x.country.countryCode?.toUpperCase() === countryCode
          );

          this.setCountry(country);

          const regionCode =
            country!.country!.locationCode || country!.country!.countryCode;
          setRegionCode(regionCode);
        } catch (error) {
          await this.props.getGroupCountryList();
        }
      }

      if (user.signupDetails) {
        let fields = this.state.fields;

        fields.firstName = user.signupDetails.firstName;
        fields.middleName = user.signupDetails.middleName;
        fields.lastName = user.signupDetails.lastName;
        fields.birthDate = new Date(
          user.signupDetails.birthDate || null
        ).toISOString().split('T')[1];

        this.setState({ fields });
      }

      if (user.signupResidence) {
        let fields = this.state.fields;

        fields.country = user.signupResidence.country;
        fields.province = user.signupResidence.province;
        fields.provinceId = user.signupResidence.provinceId;
        fields.city = user.signupResidence.city;
        fields.cityId = user.signupResidence.cityId;
        fields.countryId = user.signupResidence.countryId;
        fields.sendBreakdowns = user.signupResidence.sendBreakdowns;
        fields.representedActor = user.signupResidence.representedActor;
        fields.unionActor = user.signupResidence.unionActor;
        fields.memberId = user.signupResidence.memberId;
        fields.onCamera = user.signupResidence.onCamera;
        fields.voice = user.signupResidence.voice;

        this.setState({ fields });
      }
    } else {
      window.location.replace("/");
    }
  }

  checkValidationStageName() {
    const { t } = this.props;
    let fields: IActorStageName & IActorFilmCenter = this.state.fields;
    let errors: IActorError = {} as IActorError;

    if (!fields.firstName) {
      errors.FirstName = t("First Name is required");
    } else if (fields.firstName.trim().length < 2) {
      errors.FirstName = t("Minimum 2 characters");
    } else if (!nameRegex.test(fields.firstName)) {
      errors.FirstName = t(
        "Name can only contain letters, spaces, -, ' and `."
      );
    }

    if (!fields.lastName) {
      errors.LastName = t("Last Name is required");
    } else if (fields.lastName.trim().length < 2) {
      errors.LastName = t("Minimum 2 characters");
    } else if (!nameRegex.test(fields.lastName)) {
      errors.LastName = t(
        "Name can only contain letters, spaces, -, ' and `."
      );
    }

    return errors;
  }

  checkValidationResidence() {
    const { t } = this.props;
    let fields: IActorStageName & IActorFilmCenter = this.state.fields;
    let errors: IActorError = {} as IActorError;

    if (!this.state.ageChecked) {
      errors.ageChecked = t("This field is mandatory");
    }

    //country
    if (!fields.country) errors.Country = t("Country field is required");

    //city
    if (!fields.city) errors.City = t("City field is required");

    if (fields.unionActor) {
      if (!fields.memberId) {
        errors.MemberId = t("Membership ID is required");
      } else {
        if (!fields.memberId.match(/^[0-9 \.\-`]+$/i)) {
          errors.MemberId = t("Only numbers are accepted");
        }

        if (fields.memberId.length < 6) {
          errors.MemberId = t("Membership ID is invalid");
        }
      }
    }

    return errors;
  }

  checkValidation() {
    const stageNameErrors = this.checkValidationStageName();
    const residenceErrors = this.checkValidationResidence();

    const errors = {
      ...stageNameErrors,
      ...residenceErrors,
    };

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    const isStageNameChanged = [
      "firstName", "lastName", "middleName", "birthDate",
    ].some(fieldName => fieldName === e.target.name);
    const isResidenceChanged = [
      "country", "province", "provinceId", "city", "cityId",
      "countryId", "sendBreakdowns", "representedActor",
      "unionActor", "memberId", "onCamera", "voice",
    ].some(fieldName => fieldName === e.target.name);

    this.setState(
      {
        fields: {
          ...this.state.fields,
          [e.target.name]: e.target.value,
        },
        isStageNameChanged,
        isResidenceChanged,
      },
      () => {
        this.state.isFormSubmitClicked && this.checkValidation();
      }
    );
  };

  handleBlur = async () => {
    // TODO

    // We want this to happen when the user deselects a field
    // If Stage Name or Residence are filled submit on change
    if (
      this.state.isStageNameChanged
      && Object.keys(this.checkValidationStageName()).length === 0
    ) {
      await this.createStageName({ isLoadingDisabled: true });
      this.setState({
        isStageNameChanged: false,
      });
    }

    // if (
    //   this.state.isResidenceChanged
    //   && Object.keys(this.checkValidationResidence()).length === 0
    // ) {
    //   await this.createActorResidence();
    //   resetFlags.isResidenceChanged = false;
    // }
  }

  handleAgeCheck = (e: any) => {
    this.setState(
      {
        ageChecked: !this.state.ageChecked,
      },
      () => {
        this.state.isFormSubmitClicked && this.checkValidation();
      }
    );
  };

  createNameAndResidence = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;

    await this.createStageName();
    await this.createActorResidence();
    await this.createActorAccountPartial();

    const { location } = this.props;
    if (qs.parse(location.search).offer) {
      this.moveToNextOffer();
    } else {
      this.moveToNext();
    }
  }

  createStageName = async (options?: { isLoadingDisabled: boolean }) => {
    let birthDateString = null;
    if (this.state.fields.birthDate) {

    }

    try {
      birthDateString = this.state.fields.birthDate.toISOString();
    } catch (e) {
      try {
        birthDateString = moment(this.state.fields.birthDate).toISOString(true);
      } catch (e2) {
        birthDateString = null;
      }
    }

    try {
      const dto = {
        ...this.state.fields,
        birthDate: birthDateString,
      }
      if ('isLoadingDisabled' in options) {
        dto.isLoadingDisabled = options.isLoadingDisabled;
      }
      await this.props.createStageName(dto);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  createActorResidence = async () => {
    try {
      await this.props.createActorResidence(this.state.fields);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  createActorAccountPartial = async () => {
    try {
      await this.props.createActorAccountPartial(this.state.fields);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect();
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      // this.props.history.push({
      //   pathname: "/actor/4",
      //   search: this.props.location.search,
      // });
      const sessionId = getSessionId();

      if (sessionId) {
        window.location.href = `${process.env.REACT_APP_CWB_Membership_Base}/promo?k=${getWebSessionKey()}&sessionId=${sessionId}`;
      } else {
        window.location.href = `${process.env.REACT_APP_CWB_Membership_Base}/promo?k=${getWebSessionKey()}`;
      }

    }
  };

  moveToNextTrial = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect();
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/special/4",
        search: this.props.location.search,
      });
    }
  };

  moveToNextCode = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect();
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/trial/4",
        search: this.props.location.search,
      });
    }
  };

  moveToNextOffer = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect();
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/offer/4",
        search: this.props.location.search,
      });
    }
  };

  moveToCheckout = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect();
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/free-trial/completed",
        search: this.props.location.search,
      });
    }
  };

  getCountry = (): IGroupedCountry => {
    const fields: IActorFilmCenter = this.state.fields;
    let country = this.props.groupCountryList.find(
      (x) => x.id === fields.countryId
    );

    if (fields.province && fields.country) return country;

    const qsArgs = qs.parse(this.props.location.search);
    let countryCode = getCountryCode();
    if (countryCode)
      country = this.props.groupCountryList.find(
        (x) => x.value === countryCode || x.value?.toUpperCase() == countryCode.toUpperCase()
      );
    else if (qsArgs.ctry)
      country = this.props.groupCountryList.find(
        (x) => x.value === qsArgs.ctry || x.value?.toUpperCase() == qsArgs.ctry.toString().toUpperCase()
      );

    return country;
  };

  handleCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = '';
    fields.cityId = 1;

    fields.country = option.value;
    fields.countryId = option.id;
    fields.provinceId = option.country!.provinceId;
    fields.province = option.country!.provinceName;
    this.setState({ fields });
    this.setCountry(option);

    const regionCode =
      option!.country!.locationCode || option!.country!.countryCode;
    setRegionCode(regionCode);

    this.state.isFormSubmitClicked && this.checkValidation();
  };

  setCityList = (list: any) => {
    const cityList = list
      ? list.map((city: any) => ({
        label: city.cityName,
        value: city.cityName,
        id: city.cityId,
        city,
      }))
      : [];

    this.setState({
      cityList,
    });
  }

  setCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    try {
      fields.country = option.value;
      fields.countryId = option.id;

      if (option.cityList) {
        this.setCityList(option.cityList);
      }

      this.setState({
        fields,
        isResidenceChanged: true,
      });
    } catch (err) {
      // nothing needed here... an unhandled error sometimes occurs if the country.cities are not loaded yet and the user click the city dropdown
    }
  };

  handleCity = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = option.value;
    fields.cityId = option.id;
    fields.provinceId = option.city!.provinceId;
    fields.province = option.city!.provinceName;
    this.setState({
      fields,
      isResidenceChanged: true,
    });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleSendBreakdowns = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.sendBreakdowns = newChoice;
    this.setState({ fields });
  };

  handleRepresentedActor = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.representedActor = newChoice;
    this.setState({ fields });
  };

  handleUnionActor = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.unionActor = newChoice;
    if (!fields.unionActor) {
      fields.memberId = '';
    }
    this.setState({ fields });
  };

  mapUserToStateFields = (userfilmCenter: ISignupResidence) => {
    if (!userfilmCenter) return;
    let fields: ISignupResidence = this.state.fields;

    fields.province = userfilmCenter.province;
    fields.provinceId = userfilmCenter.provinceId;
    fields.city = userfilmCenter.city;
    fields.cityId = userfilmCenter.cityId;
    fields.countryId = userfilmCenter.countryId;
    fields.onCamera = userfilmCenter ? userfilmCenter.onCamera : false;
    fields.voice = userfilmCenter ? userfilmCenter.voice : false;
    fields.sendBreakdowns = userfilmCenter ? userfilmCenter.sendBreakdowns : false;

    fields.representedActor = userfilmCenter ? userfilmCenter.representedActor : false;
    fields.unionActor = userfilmCenter ? userfilmCenter.unionActor : false;
    fields.memberId = userfilmCenter.memberId;

    this.setState({ fields });

    const grpCountries = this.props.groupCountryList;
    const country = grpCountries.find((x) => x.id === fields.countryId);
    this.setCountry(country);

    const regionCode =
      country!.country!.locationCode || country!.country!.countryCode;
    setRegionCode(regionCode);
  };

  render() {
    const { t, location } = this.props;
    const errors = this.props.errors;
    const fields = this.state.fields;
    const ageChecked = this.state.ageChecked;

    const hoverTip = (
      <StyledTooltip
        placement="top"
        tip={t(
          "This field is not mandatory. Some projects require youth and young adults to qualify for project eligibility by being at least a certain age."
        )}
      >
        <img alt="more info" src="../images/info.svg" />
      </StyledTooltip>
    );

    const isLangSpanish = getI18n().language === "es";
    // const special = qs.parse(location.search).special;
    const special = false;

    // Film center
    const country = this.getCountry();
    const city = this.state.cityList.find((x) => x.id === fields.cityId);

    return (
      <PageContainer>
        <Wrapper>
          <StyledTypography align="center" component="h1" variant="h2">
            {t("Profile Information")}
          </StyledTypography>
          <StyledForm onSubmit={this.createNameAndResidence} onBlur={this.handleBlur}>
            <Typography color="darkGrey" component="h2" variant="h5">
              {special
                ? t("Tell us your stage name for casting")
                : t("What's your stage name?")}
            </Typography>
            <Section>
              <SectionTitle>{t("First Name")}</SectionTitle>
              <StyledInput
                name="firstName"
                type="text"
                className={errors.FirstName ? "invalid" : ""}
                maxLength={NameMaxLength}
                onChange={this.handleChange}
                value={fields.firstName}
              />
              <SmallTextError className="error">
                <span>{errors.FirstName}</span>
              </SmallTextError>
            </Section>
            {/* {!isLangSpanish && (
              <Section>
                <SectionTitle>
                  {t("Middle Name (If used professionally)")}
                </SectionTitle>
                <StyledInput
                  name="middleName"
                  type="text"
                  className={errors.MiddleName ? "invalid" : ""}
                  maxLength={NameMaxLength}
                  onChange={this.handleChange}
                  value={fields.middleName}
                />
                <SmallTextError className="error">
                  <span>{errors.MiddleName}</span>
                </SmallTextError>
              </Section>
            )} */}
            <Section>
              <SectionTitle>{t("Last Name")}</SectionTitle>
              <StyledInput
                type="text"
                name="lastName"
                maxLength={NameMaxLength}
                className={errors.LastName ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.lastName}
              />
              <SmallTextError className="error">
                <span>{errors.LastName}</span>
              </SmallTextError>
            </Section>
            <StyledH2 color="darkGrey" component="h2" variant="h5">
              {t("When's your birthday?")}
              {hoverTip}
            </StyledH2>
            <Section>
              <BirthDatePicker
                name="birthDate"
                value={fields.birthDate}
                onChange={this.handleChange}
              />
            </Section>
            <Section>
              <CheckboxWrapperStage onClick={this.handleAgeCheck}>
                <AgeCheckbox className="small-cb" isChecked={ageChecked} />
                <StyledLabel>
                  {t(
                    "I am currently at least 18 years old or this is being filled by a parent or guardian"
                  )}
                </StyledLabel>
              </CheckboxWrapperStage>
              <SmallTextError className="error">
                <span>{errors.ageChecked}</span>
              </SmallTextError>
            </Section>

            <Section className="margin-bottom-none">
              <LargeText>{t("Film center")}</LargeText>
            </Section>
            <Section className="">
              <SectionTitle>{t("Choose your country")}</SectionTitle>
              <CWBDropdown
                value={country}
                isValueSelected={true}
                placeholder={t("Select Country")}
                items={this.props.groupCountryList}
                onChange={this.handleCountry}
                className={errors.Country ? "validationError" : ''}
              />
              <ErrorText>{t(errors.Country) || errors.Country}</ErrorText>
            </Section>
            <Section className="margin-bottom-none">
              <SectionTitle>{t("Choose your nearest city")}</SectionTitle>
              <CWBDropdown
                value={city || null}
                isValueSelected={true}
                placeholder={t("Select an Option")}
                items={this.state.cityList ? this.state.cityList : []}
                onChange={this.handleCity}
                className={errors.City ? "validationError" : ''}
              />
              <ErrorText>{t(errors.City) || errors.City}</ErrorText>
            </Section>
            <Section className="margin-top-none">
              <div onClick={() => this.handleSendBreakdowns(!fields.sendBreakdowns)}>
                <InlineCheckbox isChecked={fields.sendBreakdowns} id="breakdowns-check" />
                <StyledCustomLabel htmlFor="breakdowns-check">{t("Send me breakdowns for my city")}</StyledCustomLabel>
              </div>
              {country?.id === 2 || country?.id == 18 ?
                (
                  <div onClick={() => this.handleRepresentedActor(!fields.representedActor)}>
                    <InlineCheckbox isChecked={fields.representedActor} id="representedActor-check" />
                    <StyledCustomLabel htmlFor="representedActor-check">{t("I'm a represented actor")}</StyledCustomLabel>
                  </div>
                ) : (<></>)}

              {country?.id === 2 ?
                (<>
                  <div onClick={() => this.handleUnionActor(!fields.unionActor)}>
                    <InlineCheckbox isChecked={fields.unionActor} id="unionActor-check" />
                    <StyledCustomLabel htmlFor="unionActor-check">{t("I'm a US SAG-AFTRA member")}</StyledCustomLabel>
                  </div>

                  {fields.unionActor ?
                    (<div style={{ marginRight: "25px" }}>
                      <SectionTitle>{t("SAG-AFTRA member ID")}</SectionTitle>
                      <BlendedStyledInput style={{ marginTop: "8px", maxWidth: "290px" }}
                        error={Boolean(errors.MemberId)}
                        errorMessage={t(errors.MemberId) || errors.MemberId}
                        name="memberId"
                        maxLength="12"
                        value={fields.memberId}
                        onChange={this.handleChange}
                      />
                    </div>) : <></>
                  }
                </>
                ) : (<></>)}
            </Section>
            <Section>
              <StyledButton disabled={this.props.isLoading}>
                {t("Continue")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledButton>
            </Section>
          </StyledForm>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  width: 480px;
  width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px 50px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  & input {
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.midGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown-wrapper .validationError {
    .cwb-dropdown__control {
      border-color: ${(p) => p.theme.errorColor} !important;
    }
    .cwb-dropdown__placeholder {
      color: ${(p) => p.theme.errorColor} !important;
    }
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;

  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
  background-color: ${(p) => p.theme.palette.brand.lightBlue};

  &:hover {
    background-color: ${(p) => p.theme.palette.brand.blue};
  }

  &:active {
    background-color: ${(p) => p.theme.palette.brand.darkBlue};
  }
`;

const StyledH2 = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledLabel = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.color} !important;
  line-height: 16px;
  cursor: pointer;
`;

const AgeCheckbox = styled(StyledCheckbox)`
  cursor: pointer;
`;

const InlineCheckbox = styled(CWBCheckbox)`
  display:inline-block;
`;

const StyledCustomLabel = styled.label`
  font-size: ${(p) => p.theme["xs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 16px;
  cursor: pointer;
`;

interface StateProps {
  errors: IActorError;
  isLoading: boolean;
  groupCountryList: IGroupedCountry[];
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    isLoading: state.actorModel.isLoading,

    groupCountryList: state.referencesModel.supportedGroupCountryList,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createStageName: (dto: IActorStageName) => void;
  setClientId: (clientId: string) => void;
  setIsLoading: (status: boolean) => void;
  createActorResidence: (dto: ISignupResidence) => void;
  createActorAccountPartial: (dto: ISignupResidence) => void,

  getGroupCountryList: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createStageName: dispatch.actorModel.createStageName,
    setClientId: dispatch.actorModel.setClientId,
    setIsLoading: dispatch.actorModel.setIsLoading,
    createActorResidence: dispatch.actorModel.createActorResidence,
    createActorAccountPartial: dispatch.actorModel.createActorAccountPartial,

    getGroupCountryList: dispatch.referencesModel.getSupportedGroupCountryList,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorStageNameStep)
);
