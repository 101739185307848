import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @ts-ignore
import { CWBLoadingCrest } from "cwb-react";
import qs from "query-string";
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from "styled-components";

interface IProps extends RouteComponentProps, StateProps, DispatchProps {
  t?: any;
}

const ActorResumeSignup: React.FC<IProps> = ({
  checkSignupState,
  signupStageStartup,
  history,
  location,
}) => {
  const [isStageRetrieved, setStageRetrieved] = useState(false);
  useEffect(() => {
    const params = qs.parse(location.search);

    if (!params.sessionId) {
      history.push("/actor");
      return;
    } else if (Array.isArray(params.sessionId)) {
      console.error("Invalid sessionId value (only 1 value allowed)");
      history.push("/actor");
      return;
    }

    checkSignupState(params.sessionId)
      .then(() => setStageRetrieved(true));
  }, []);

  if (isStageRetrieved) {
    if (signupStageStartup >= 3 && signupStageStartup <= 6) {
      history.push('/actor/3');
    }
  }

  return (
    <Wrapper>
      <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  padding-top: 60px;

  @media all and (max-width: 768px) {
    padding-top: 0;
  }
`;

interface StateProps {
  signupStageStartup: number | null;
}

const mapStateToProps = (state: any) => ({
  signupStageStartup: state.actorModel.signupStageStartup,
});

interface DispatchProps {
  checkSignupState: (sessionId: string) => Promise<undefined>;
}

const mapDispatchToProps = (dispatch: any) => ({
  checkSignupState: dispatch.actorModel.checkSignupState,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActorResumeSignup)
);
