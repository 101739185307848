import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
// @ts-ignore
import { CWBDropdown } from "cwb-react";
import { getCountryCode } from "../helpers/index";
import CastingUserStep from "./Director/CastingUserStep";
import AgentRegistration from "./Agent/AgentRegistration";
import { withRouter } from "react-router-dom";
import ActorCredentialStep from "./Actor/SignUp/ActorCredentialStep";
import Modal from "./Common/Modal";
import { Section, SectionTitle } from "./Common/StyledComponents";
import { iRootState } from "../store";
import { connect } from "react-redux";
import CastingSellingFeatures from "./Director/CastingSellingFeatures";
import ProducerSellingFeatures from "./Director/ProducerSellingFeatures";
import AgentSellingFeatures from "./Agent/AgentSellingFeatures";
import ActorSellingFeaturesNew from "./Actor/ActorSellingFeaturesNew";
import { cleanCache } from "../helpers/index";

interface IState {
  selectedAccount: any;
  accounts: Array<object>;
}

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history: any;
  location: any;
  match: any;
}

class Registration extends React.Component<IProps, IState> {
  public state: IState = {
    selectedAccount: null,
    accounts: [
      { id: 1, value: "actor", label: this.props.t("Actor") },
      { id: 2, value: "agent", label: this.props.t("Agent") },
      {
        id: 3,
        value: "casting",
        label:
          getI18n().language === "es"
            ? this.props.t("Casting Director / Talent Scout")
            : this.props.t("Casting Director"),
      },
    ],
  };

  async componentWillMount() {
    cleanCache();

    const { match } = this.props;
    if (match && match.params && match.params.accountType) {
      let selected = this.state.accounts.find(
        (x: any) => x.value === match.params.accountType
      );

      if (!selected && match.params.accountType) {
        selected = this.state.accounts.find(
          (x: any) => match.params.accountType.toString().toLocaleLowerCase().startsWith(x.value.toString().toLocaleLowerCase())
        );
      }
      if (selected) {
        this.setState({ selectedAccount: selected });
      } else {
        window.location.href = "/register";
      }
    } else {
      this.setState({ selectedAccount: this.state.accounts[0] });
    }

    const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
    if (qsArgs.dev && qsArgs.ctry) {
      await this.props.setForceRequesterCountryCode(qsArgs.ctry.toString())
    } else {
      await this.props.setRequesterCountryCode();
    }
  }

  async componentDidMount() {
    if (!this.props.location.search) {
      return;
    }

    if (window.innerWidth > 768)
      return;

    const qsArgs = qs.parse(this.props.location.search);
    if (qsArgs.page && qsArgs.page.toString().toLocaleLowerCase() === "openbd" && qsArgs.bd) {
      window.requestAnimationFrame(function () {
        let node = document.getElementById("get-started")
        if (!node) {
          node = document.getElementById("sub-title");
        }
        if (node !== undefined) {
          setTimeout(() => {
            node.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }, 100)
        }
      });
    }
  }


  render() {
    const { t, countryCode } = this.props;
    const { selectedAccount } = this.state;
    const accounts = this.state.accounts.map((option: any) => ({
      ...option,
      label: t(option.label),
    }));

    const userCountryCode = countryCode || getCountryCode();

    const qsArgs = qs.parse(this.props.location.search);
    const isProducer = qsArgs.subType === "producer";
    return (
      <Wrapper>
        <WrapperColumns>
          {selectedAccount.id === 1 ? (
            <ActorSelling>
              <ActorSellingFeaturesNew />
            </ActorSelling>
          ) : (
            <BodySellingFeatures>
              {selectedAccount.id === 2 && <AgentSellingFeatures />}
              {selectedAccount.id === 3 && !isProducer && (
                <CastingSellingFeatures />
              )}
              {selectedAccount.id === 3 && isProducer && (
                <ProducerSellingFeatures />
              )}
            </BodySellingFeatures>
          )}
        </WrapperColumns>
        <WrapperColumns>
          <Body>
            <StyledTitle id="sub-title">{t("Let’s create your account")}</StyledTitle>
            {!isProducer && (
              <Section>
                <SectionTitle>
                  {t("What type of account would you like to create?")}
                </SectionTitle>
                <CWBDropdown
                  // isDisabled={true}
                  className="account-dropdown"
                  value={this.state.selectedAccount}
                  isValueSelected={true}
                  placeholder={!this.state.selectedAccount && "Select Option"}
                  items={accounts}
                  onChange={(option: any) => {
                    this.setState({ selectedAccount: option });
                    if (window.innerWidth > 768)
                      return;

                    window.requestAnimationFrame(function () {
                      let node = document.getElementById("get-started")
                      if (!node) {
                        node = document.getElementById("sub-title");
                      }
                      if (node !== undefined) {
                        node.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    });
                  }}
                />
              </Section>
            )}
            {selectedAccount.id === 1 && (
              <ActorCredentialStep
                {...this.props}
                countryCode={userCountryCode}
              />
            )}
            {selectedAccount.id === 2 && <AgentRegistration {...this.props} />}
            {selectedAccount.id === 3 && <CastingUserStep {...this.props} />}
          </Body>
        </WrapperColumns>
      </Wrapper>
    );
  }
}

interface StateProps {
  countryCode: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    countryCode: state.referencesModel.countryCode,
  };
}

interface DispatchProps {
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
  };
}

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration))
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media all and (max-width: 1023px) {
    
    display: block;
    & > div {
      display:flex;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      max-width: 80% !important;
    }
    & > div > div {
      margin-bottom: 0px !important;
    }

    & > div:last-of-type {
      margin-top: 0px;
      padding-bottom: 40px;
    }
  }

  @media all and (max-width: 768px) {
    display:block;
    & > div {
      max-width: 90% !important;
    }

    & > div:last-of-type {
      margin-top: 20px;
      padding-bottom: 40px;
    }
  }

  @media all and (max-width: 520px) {
    flex-direction: column;
    background-color: white;
    & > div {
      max-width: 99% !important;
      margin-top: 1px !important;
    }
  }
`;

const WrapperColumns = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  flex: 0 0 50%;
  max-width: 50%;

  @media all and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @media all and (min-width: 1024px) {
    padding-left:30px !important;
  }
`;

const BodySellingFeatures = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 480px;
  max-width: auto;
  min-width: 480px;
  margin: 40px auto 60px auto;

  float: right !important;

  @media all and (max-width: 768px) {
    max-width: 680px;
    min-width: 40%;
    float: none !important;
    margin-bottom: 0;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    margin-top: 10px !imporntat;
  }
`;

const ActorSelling = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  max-width: 530px;
  min-width: 480px;
  margin: 40px auto 60px auto;

  float: right !important;

  @media all and (max-width: 768px) {
    margin-top: 20px;
    float:none !important;
  }

  @media all and (max-width: 520px) {
    max-width: 680px;
    min-width: 40%;
    float: none !important;
    margin-bottom: 0;
    width: 100%;
  }
`;

const Body = styled(Modal)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  margin: 40px auto 60px auto;
  padding: 40px 70px;

  float: left !important;

  @media all and (max-width: 768px) {
    max-width: 560px;
    min-width: 40%;
    float: none !important;
    padding: 10px 10px 40px 10px;
    margin-top: 0;

  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
      font-weight: ${(p) => p.theme["font-weight-600"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.lightGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTitle = styled.h2`
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-bottom: 8px;

  @media all and (max-width: 768px) {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 20px;
  }

`;
