import React, { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Footer,
  Modal,
  PageContainer,
  Typography
} from '../Common';
import CWBLabsActorRegistrationComplete from 'components/CWBLabs/ActorRegistrationComplete';
import { getWebSessionKey } from "../../helpers";

interface Props extends StateProps, RouteComponentProps {
  t: any;
  free?: boolean;
  isRenewed?: boolean
}

const ActorRegistrationComplete: React.FC<Props> = ({
  t,
  location,
  clientId,
  free,
  isRenewed
}) => {

  const qsArgs = qs.parse(location.search);
  const isJob = qsArgs.page === 'openbd' || qsArgs.page === 'submission' || (qsArgs.bd && qsArgs.bd.length > 4);

  const redirect = useCallback(() => {
    const baseUrl = process.env.REACT_APP_CWB_Site;
    const k = getWebSessionKey();

    let redirectObject: any = {
      login: clientId,
      ...(qs.parse(location.search))
    };

    if (isRenewed) {
      redirectObject.existing = 1;
    } else {
      if (!qsArgs.existing) {
        redirectObject.signUp = 1;
      } else {
        redirectObject.existing = 1;
      }
    } 

    if (isJob && !redirectObject.page) {
      redirectObject.page = 'openbd';
    };

    if (k) {
      redirectObject.k = k;
      const params = qs.stringify(redirectObject);
      window.location.replace(`${baseUrl}/login/go?${params}`);
    } else {
      const params = qs.stringify(redirectObject);
      window.location.assign(`${process.env.REACT_APP_CWB_Site}/login/go?${params}`);
    }
  }, [clientId, location.search]);

  const [remainingTime, setRemainingTime] = useState<number>(19);
  useEffect(() => {
    if (remainingTime === 0) {
      redirect();
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, redirect]);

  return qsArgs.cwblabs ? (
    <CWBLabsActorRegistrationComplete />
  ) : (
    <PageContainer>
      <StyledModal>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
          {free ?
            t(
              'Thank you for signing up for your Free Casting Workbook account!'
            ) :
            (isRenewed ? t("Thank you for renewing your Pro Casting Workbook account!") :
              t(
                'Thank you for signing up for your Pro Casting Workbook account!'
              ))
          }
        </Typography>
        <Icon alt="check" src="/images/icon-tick.svg" />
        {
          free ?
            (<StyledTypography
              align="center"
              color="darkGrey"
            >
              {qsArgs.sag ? <>{t(
                'You now have access to Casting Workbook')}
              </> : (<>{t(
                'You now have access to Casting Workbook’s Lite Member.'
              )}</>)}
              <br></br>
              {qsArgs.sag ? <>{t("US SAG-AFTRA members will receive an email with further instructions")}
              </> : <>{t(
                'You can upgrade at anytime for full access to all services.'
              )}</>}
            </StyledTypography>)
            :
            <StyledTypography
              align="center"
              color="darkGrey"
            >
              {isRenewed ? "" :
                t('You now have access to all of Casting Workbooks Pro features and content!')
              }
            </StyledTypography>
        }
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('On a mobile device? Go to the App Store or Google Play store to download the powerful Actor App!')}
        </StyledTypography>
        {
          isJob ? <StyledTypography
            align="center"
            color="darkGrey"
          >
            {t("You will automatically be redirected to the submission page, in")}
            &nbsp;<span>{remainingTime}</span>
            {t('seconds.')}
          </StyledTypography> : <StyledTypography
            align="center"
            color="darkGrey"
          >
            {t("You will automatically be redirected to the home page, in")}
            &nbsp;<span>{remainingTime}</span>
            {t('seconds.')}
          </StyledTypography>
        }
        <Button onClick={() => redirect()}>
          {isJob ? t("Go to submission") : t("Go to Casting Workbook")}
        </Button>
      </StyledModal>
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(11, 18, 15)};
  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: ${p => p.theme.spacing(3, 6)};
  padding: 7px 7px 6px;
  background-color: ${p => p.theme.palette.system.success};
  border-radius: 50%;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing(3)};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;

interface StateProps {
  clientId: string;
}

const mapStateToProps = (state: any) => ({
  clientId: state.actorModel.clientId
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ActorRegistrationComplete)));
