import { IGroupedCountry } from "./ICountryList";
import i18n from "../i18n";
import DOMPurify from "dompurify";
import { TrackingData } from "shared/api/dtos/ITrackingDataDto";
import Cookies from "js-cookie";
import qs from "query-string";
// @ts-ignore
import * as Sentry from "@sentry/react";

// @ts-ignore
import { moment } from "cwb-react";

const shortid = require("shortid");
export const generateId = () => `item-${shortid.generate()}`;

export const localeKeys: any = { en: 0, fr: 1, en_uk: 2, es: 3, nb: 99 };

export const errorPageRedirect = (errMessage?: string) => {
  console.trace('REDIRECTION', errMessage);
  if (process.env.NODE_ENV !== 'development') {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
  }
}

export const timedSentrySendThenErrorPage = (e: any) => {
  Sentry.captureException(e);
  setTimeout(() => errorPageRedirect(), 1000);
};

export const getUserLanguageChoice = (
  localeKeys: any,
  languageChoice: number
): string => {
  return Object.keys(localeKeys).find((key: string) => {
    return localeKeys[key] === languageChoice;
  });
};

export const detectBrowserLang =
  (navigator.languages && navigator.languages[0]) || navigator.language;

export const getBrowserLang = () => {
  const browserLang = detectBrowserLang.toLowerCase();
  if (browserLang === "en-gb") {
    return "en_uk";
  } else if (localeKeys[browserLang.split("-")[0]]) {
    return browserLang.split("-")[0];
  } else {
    return "en";
  }
};

export const trySetSpanishBrowserLang  = () => {

  const languages = [];

  if (navigator.languages && navigator.languages) 
  {
    for (let i = 0; i < navigator.languages.length; i++) {
      const element = navigator.languages[i];
      languages.push(element);
      if (i == 2)
        break;
    }
  } else {
    languages.push(navigator.language)
  }

  const esLanguage = languages.find(a => a.toLocaleLowerCase().startsWith("es"))
  if (esLanguage) {
    i18n.changeLanguage(esLanguage);
    moment.moment.locale(esLanguage);
  } 
}

export const setBrowserLang = (lang?: string) => {
  if (lang) {
    i18n.changeLanguage(lang);
    return;
  }

  const browserLang = detectBrowserLang.toLowerCase();
  if (browserLang === "en-gb") {
    i18n.changeLanguage("en_uk");
  } else if (localeKeys[browserLang.split("-")[0]]) {
    i18n.changeLanguage(browserLang.split("-")[0]);
  } else {
    i18n.changeLanguage("en");
  }
};

export const setCurUserSignUp = (user: any) => {
  if (user && user.sessionId) {
    // This saved CWB_USER info is used by all API calls and in the user.api.js to cache responses
    localStorage.setItem(
      "CWB_SIGNUP_USER",
      JSON.stringify({ cachedAt: new Date().valueOf(), ...user })
    );
    sessionStorage.setItem("Session_Id", user.sessionId);
  } else {
    localStorage.removeItem("CWB_SIGNUP_USER");
    sessionStorage.removeItem("Session_Id");
  }
};

export const setUserActorSignUpId = (id: string) => {
  if (id == null) {
    localStorage.removeItem("Actor_SignUp_Id");
    return;
  }
  localStorage.setItem("Actor_SignUp_Id", id);
};

export const setCountryCode = (code: string) => {
  localStorage.setItem("CountryCode", code);
};

export const getCountryCode = () => {
  return localStorage.getItem("CountryCode");
};

export const setRegionCode = (code: string) => {
  localStorage.setItem("RegionCode", code);
};

export const getRegionCode = () => {
  return localStorage.getItem("RegionCode");
};

export const getUserActorSignUpId = () => {
  let userSignUpId = JSON.parse(localStorage.getItem("Actor_SignUp_Id"));
  if (!userSignUpId) return 0;
  return userSignUpId;
};

export const getCurUserSignUp = () => {
  let curUser = JSON.parse(localStorage.getItem("CWB_SIGNUP_USER"));
  if (!curUser || !curUser.sessionId) {
    return 0;
  }

  return curUser;
};

export const getSessionId = () => {
  let sessionId = sessionStorage.getItem("Session_Id");
  if (!sessionId) {
    return "";
  }

  return sessionId;
};

export const getWebSessionKey = () => {
  let webSessionKey = localStorage.getItem("CWB_WebSessionKey");
  if (!webSessionKey) {
    return "";
  }

  const timeStamp = localStorage.getItem("CWB_Timestamp")
  if (!timeStamp) {
    return webSessionKey
  }

  const t1 = Number(timeStamp)
  const t2 = new Date().getTime();;
  if (Number.isFinite(t1) && !Number.isNaN(t1)) {
    var hours = Math.abs(t2 - t1) / 36e5;
    if (hours > 16) {
      localStorage.setItem("CWB_WebSessionKey", "");
      localStorage.removeItem("CWB_Timestamp");
      return "";
    }
  }
  return webSessionKey;
};

export const setWebSessionKey = (k: string) => {
  if (k) {
    localStorage.setItem("CWB_WebSessionKey", k);
    localStorage.setItem("CWB_Timestamp", new Date().getTime().toString())
  }
};

export const setCustomCouponCode = (id?: number, customCouponCode?: string) => {
  if (!customCouponCode)
    return;

  let dict: any = null;
  const jsonDict = sessionStorage.getItem("CWB_CustomCode");
  if (jsonDict) {
    dict = JSON.parse(jsonDict);
  }
  if (!dict) {
    dict = {};
  }
  const key = (id || -1).toString();
  dict[key] = customCouponCode;
  sessionStorage.setItem("CWB_CustomCode", JSON.stringify(dict))
}

let tempProductInfoStore: any = {};
export const setCustomProductInfo = (id?: number, productCode?: string, productServiceId?: number) => {
  if (!productCode)
    return;

  let dict: any = null;
  const jsonDict = sessionStorage.getItem("CWB_CustomProduct");
  if (jsonDict) {
    dict = JSON.parse(jsonDict);
  }
  if (!dict) {
    dict = {};
  }
  const key = (id || -1).toString();
  dict[key] = { productCode: productCode, serviceId: productServiceId };
  // sessionStorage.setItem("CWB_CustomProduct", JSON.stringify(dict))
  tempProductInfoStore = dict;
}

export const getCustomCouponCode = (id: number) => {
  const key = (id || -1).toString();

  const jsonDict = sessionStorage.getItem("CWB_CustomCode");
  let dict: any = null;
  if (jsonDict) {
    dict = JSON.parse(jsonDict);
  }

  if (!dict)
    return '';

  return (dict[key] || '');
}

export const deleteCustomProductInfo = (id: number) => {
  const key = (id || -1).toString();

  // const jsonDict = sessionStorage.getItem("CWB_CustomProduct");
  // let dict: any = null;
  // if (jsonDict) {
  //   dict = JSON.parse(jsonDict);
  // }
  let dict = tempProductInfoStore;

  dict[key] = null;
  delete dict[key];
  // sessionStorage.setItem("CWB_CustomProduct", JSON.stringify(dict))
}

export const getCustomProductInfo = (id: number) => {
  const key = (id || -1).toString();

  // const jsonDict = sessionStorage.getItem("CWB_CustomProduct");
  // let dict: any = null;
  // if (jsonDict) {
  //   dict = JSON.parse(jsonDict);
  // }

  let dict = tempProductInfoStore;
  if (!dict)
    return '';

  return (dict[key] || {});
}

// TODO: move this to a more narrowed helper like apiHelper...
export const postData = (url: any, data: any) => {
  // Default options are marked with *
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    credentials: "include", // include, *same-origin, omit
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      CWB: "Test",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }); // parses JSON response into native JavaScript objects
};

// TODO: review and return Dict and create inteface for Select option items
export const groupCountryList = (countryWithCities: any): IGroupedCountry[] => {
  const counties = countryWithCities.reduce(function (r: any, country: any) {
    const category = country.countryId;
    !r[category]
      ? (r[category] = {
        label: country.countryName,
        value: country.countryCode,
        id: category,
        country,
        cityList: [country],
      })
      : r[category]["cityList"].push(country);
    return r;
  }, {}),
    result = Object.keys(counties).map(function (k) {
      return counties[k];
    });
  return result;
};

export const VerifyPhoneCodeLength = 4;

export const NameMaxLength = 55;

export const PasswordLength = 6;

export const buildSearchStyle = () => ({
  control: (base: any, state: any) => ({
    minHeight: "40px !important",
    width: "68px",
    border: "1px solid #D3DDE9 !important",
    borderRadius: "4px 0 0 4px",
    display: "flex",
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: "0",
    marginRight: "0",
    maxWidth: "100%",
    color: "#122640",
    fontSize: "12px",
    fontWeight: "600",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "55%",
  }),
  dropdownIndicator: () => ({
    paddingRight: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (base: any) => ({
    ...base,
    width: "68px",
  }),
  placeholder: (base: any) => ({
    ...base,
    whiteSpace: "pre",
    textOverflow: "ellipsis",
  }),
  group: (base: any) => ({
    ...base,
    paddingTop: 2,
    overflowX: "hidden",
  }),
  groupHeading: (base: any) => ({
    ...base,
    textTransform: "titlecase",
    overflowX: "hidden",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: "4px",
    border: "1px solid #D3DDE9",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.05)",
    overflowX: "hidden",
    width: "180px",
    marginTop: "2px",
  }),
  menuList: (base: any) => ({
    ...base,
    overflowX: "hidden",
    maxHeight: "391px",
    paddingBottom: "6px",
    paddingTop: "6px",
  }),
  option: (base: any, state: any) => {
    return {
      ...base,
      backgroundColor: "#FFFFFF",
      padding: "6px 16px",
      color: "#495A6E",
      fontSize: 12,
      fontWeight: "normal",
      width: "100%",
    };
  },
});

export const getE164number = (
  phoneNumber: string,
  internationalCallingCode: string
): string => {
  let e164 = `+${internationalCallingCode}${phoneNumber}`;

  if (!e164.match(/^\+{1}[1-9]\d{1,15}$/i)) return "";

  return e164;
};

export const cleanCache = () => {
  localStorage.clear();
};

export const cleanObjectProperties = (obj: any) => {
  for (const property in obj)
    if (typeof obj[property] === "string" || obj[property] instanceof String)
      obj[property] = DOMPurify.sanitize(obj[property]);
};

export const getTrackingData = (): TrackingData => {
  let storedData = Cookies.get("tData");
  if (!storedData)
    return createFullPayloadTrackingData();

  let existing = JSON.parse(storedData) as TrackingData;
  var newTracking = createFullPayloadTrackingData();
  return mergeTrackingData(existing, newTracking);
};

const createFullPayloadTrackingData = (): TrackingData => {
  let current = window.location.search;

  const urlParams = new URLSearchParams(current);
  const myParam = urlParams.get('src')

  return {
    queryParams: current,
    queryParamSource: myParam
  };
}

const mergeTrackingData = (existingSaved: TrackingData, newTracking: TrackingData): TrackingData => {
  let result = { queryParams: null, queryParamSource: null } as TrackingData;
  if (newTracking.queryParamSource) {
    result.queryParamSource = newTracking.queryParamSource;
  } else {
    result.queryParamSource = existingSaved.queryParamSource;
  }

  if (!existingSaved.queryParams) {
    result.queryParams = newTracking.queryParams
    return result;
  }

  if (!newTracking.queryParams) {
    result.queryParams = existingSaved.queryParams;
    return result;
  }

  const resultParams = new URLSearchParams();

  const oldParams = new URLSearchParams(existingSaved.queryParams);
  const newParams = new URLSearchParams(newTracking.queryParams);

  const currentKeys = Array.from(newParams.keys());
  for (let i = 0; i < currentKeys.length; i++) {
    const element = currentKeys[i];
    resultParams.set(element, newParams.get(element));
  }

  const trackingKeys = Array.from(oldParams.keys())
  for (let i = 0; i < trackingKeys.length; i++) {
    const element = trackingKeys[i];
    if (resultParams.get(element))
      continue;

    resultParams.set(element, oldParams.get(element));
  }

  result.queryParams = '?' + resultParams.toString()
  return result;
}

const buildTrackingData = (
  srcQueryString: string,
  allOtherQueryStrings: string
): TrackingData => {
  let trackingData = {} as TrackingData;
  if (srcQueryString)
    trackingData.queryParamSource = Array.isArray(srcQueryString)
      ? srcQueryString[0]
      : srcQueryString;

  if (allOtherQueryStrings)
    trackingData.queryParams = filterQueryStrings(allOtherQueryStrings);

  return trackingData;
};

const filterQueryStrings = (queryString: string) => {
  if (!queryString) return queryString;

  queryString = queryString.replace("?", "");
  const splitQStrings = queryString.split("&");

  let dict = {} as any;

  for (let i = 0; i < splitQStrings.length; i++) {
    let splitQs = splitQStrings[i].split("=");
    dict[splitQs[0]] = splitQs[1];
  }

  const blacklist = ["join", "code", "promo", "page", "redirect", "homekit", "address", "redirectUrl", "product", "returnUrl"];
  blacklist.forEach((k) => {
    if (dict.hasOwnProperty(k)) delete dict[k];
  });

  let filteredString = "";

  const dictKeys = Object.keys(dict);
  for (let i = 0; i < dictKeys.length; i++) {
    filteredString += `${dictKeys[i]}=${dict[dictKeys[i]]}`;
    if (i < dictKeys.length - 1) filteredString += "&";
  }

  return filteredString;
};

const isNewTrackingDataEqualExisting = (
  srcQueryString: string,
  allOtherQueryStrings: string
) => {
  const trackingData = buildTrackingData(srcQueryString, allOtherQueryStrings);

  const existingTrackingData = Cookies.get("tData");

  return JSON.stringify(trackingData) === existingTrackingData;
};

const containData = (srcQueryString: string, allOtherQueryStrings: string) => {
  if (!srcQueryString && !allOtherQueryStrings) return false;

  return true;
};

const doesCacheNeedUpdate = (
  srcQueryString: string,
  allOtherQueryStrings: string
) => {
  const existingTrackingData = getTrackingData();
  if (
    existingTrackingData &&
    ((existingTrackingData.queryParams &&
      existingTrackingData.queryParams.length) ||
      (existingTrackingData.queryParamSource &&
        existingTrackingData.queryParamSource.length))
  ) {
    if (!containData(srcQueryString, allOtherQueryStrings)) return false;

    if (
      containData(srcQueryString, allOtherQueryStrings) &&
      isNewTrackingDataEqualExisting(srcQueryString, allOtherQueryStrings)
    )
      return false;
  }

  return true;
};

export const setTrackingData = (
  srcQueryString: string,
  allOtherQueryStrings: string
) => {
  if (!doesCacheNeedUpdate(srcQueryString, allOtherQueryStrings)) return;

  let trackingData = buildTrackingData(srcQueryString, allOtherQueryStrings);
  Cookies.set("tData", JSON.stringify(trackingData), { expires: 14 });
};

// Return route location search query params.src
// Fallback is to use persisted tracking data
export const findTrackingData = (locationSearch: string): TrackingData => {
  if (!locationSearch) return getTrackingData();

  const params = qs.parse(locationSearch);
  if (!params) return getTrackingData();

  let trackingData = {} as TrackingData;
  if (params.src) trackingData.queryParamSource = params.src as string;

  trackingData.queryParams = locationSearch;
  return trackingData;
};

// https://jsfiddle.net/nick_gaudreau_cwb/36sjz40n/24/

export const toFixed = (x: any) => {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const toFixedTrunc = (x: any, n: any) => {
  x = toFixed(x);

  // From here on the code is the same than the original answer
  const v = (typeof x === "string" ? x : x.toString()).split(".");
  if (n <= 0) return v[0];
  let f = v[1] || "";
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += "0";
  return `${v[0]}.${f}`;
};
