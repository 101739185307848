import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { iRootState } from "../store";
import styled, { css } from "styled-components";
import qs from "query-string";
import CWBLabsHeader from "./CWBLabs/components/Header";
import HeaderComponent from "./HeaderComponent";
import { setTrackingData } from "helpers";

interface Props extends RouteProps, StateProps {
  requireAuth?: boolean;
  isCompleted?: boolean;
  notShowHeader?: boolean;
  path?: string;
}

const RouteWrapper: React.FC<Props> = ({
  actorProfile,
  authenticatedUser,
  requireAuth,
  sessionData,
  component: Component,
  ...rest
}) => {
  const params = qs.parse(rest.location.search);
  const showHeader = !params.hs;
  const isCWBLabs = false;
  // const isCWBLabs = params.cwblabs;

  useEffect(() => {
    setTrackingData(params.src as string, rest.location.search);
  }, []);

  useEffect(() => {
    validateIfRequiresAuth();
  }, []);

  const validateIfRequiresAuth = () => {
    if (requireAuth) {
      if (!authenticatedUser) {
        const queryString = qs.stringify({
          ...qs.parse(rest.location.search),
          page: "upgrade",
        });
        window.location.replace(
          `${process.env.REACT_APP_CWB_Actor_Login}?${queryString}`
        );
        return <></>;
      }

      if (authenticatedUser.id.userTypeId !== 8) {
        return (
          <Redirect
            to={{
              pathname: "/register",
              search: rest.location.search,
            }}
          />
        );
      }

      if (
        actorProfile &&
        !actorProfile.servicesPlan.isFreemium &&
        !actorProfile.servicesPlan.canUpgrade
      ) {
        const queryString = qs.stringify({
          tk: sessionData.WebSessionKey,
          cid: authenticatedUser.id.userId,
        });
        const homePath = `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`;
        window.top.location.href = homePath;
        return <></>;
      }
    }
  };

  return (
    <>
      {showHeader && (isCWBLabs ? <CWBLabsHeader /> : <HeaderComponent />)}
      <Wrapper isCWBLabs={isCWBLabs} showHeader={showHeader}>
        <Route component={Component} {...rest} />
      </Wrapper>
    </>
  );
};

const Wrapper: any = styled.div`
  display: flex;
  padding-top: 60px;

  ${(p: any) =>
    p.isCWBLabs &&
    css`
      padding-top: 80px;
    `}

  ${(props: any) =>
    !props.showHeader &&
    css`
      padding-top: 0;
    `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }

  @media all and (max-width: 768px) {
    padding-top: 0;
  }
`;

interface StateProps {
  actorProfile: any;
  authenticatedUser: any;
  sessionData: any;
}

const mapStateToProps = (state: iRootState) => ({
  actorProfile: state.appModel.actorProfile,
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData,
});

export default connect(mapStateToProps, null)(RouteWrapper);
