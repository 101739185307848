import * as http from "../http";
import { getTrackingData } from "helpers";
import { TrackingData } from "./dtos/ITrackingDataDto";
import {
  IActorSignupResponseDto,
  IBaseSignupCredentials,
  IEmailCode,
  ISignupAccountDto,
  ISignupDetails,
  ISignupPhoneRequest,
  ISignupResidence,
} from "./dtos/ISignupDto";

/** Step 1 - Signup the actor in memory and sends an e-mail for identity check */
export const createAccountTemp = (dto: IBaseSignupCredentials) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web`,
    dto,
  );
};

/** Step 2 - Sets the phone in the memory slot created by SignupActor endpoint using sessionId and sends an sms for identity check */
export const createAccountPhone = (sessionId: string, dto: ISignupPhoneRequest) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/phone/${sessionId}`,
    dto,
  );
};

/** Step 3 - Verify user's email with emailed validation code */
export const verifyAccountEmail = (sessionId: string, code: string) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/verify/email/${sessionId}/${code}`,
    {},
  );
};

/** Step 4 - Verify user's phone with the phone validation code sent through SMS */
export const verifyAccountPhone = (sessionId: string, code: string) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/verify/phone/${sessionId}/${code}`,
    {},
  );
};

/** Step 5 - Save user name, middlename, lastname and birthdate */
export const createAccountStagename = (sessionId: string, dto: ISignupDetails) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/stagename/${sessionId}`,
    dto,
  );
};

/** Step 6 - Save actor residence */
export const createAccountResidence = (sessionId: string, dto: ISignupResidence) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/residence/${sessionId}`,
    dto,
  );
};

/** Step 7 - Create actor as a client with default plan */
export const createAccountPartial = (sessionId: string) => {
  const trackingData = getTrackingData() || {} as TrackingData;

  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/create/${sessionId}`,
    trackingData,
  );
};

/** Step 8 - Complete actor client registration */
export const createAccountComplete = (sessionId: string) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/complete/${sessionId}`,
    {},
  );
};

export const verifyEmailResendCode = (dto: IEmailCode) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/resend/email/code`,
    dto,
  );
};

export const getSignupState = (sessionId: string) => {
  const qs = `?sessionId=${sessionId}`;

  return http.get<ISignupAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/state${qs}`,
    {}
  );
};
