import * as http from "../http";
import { ICwbServiceDto } from "./dtos/IPerformerTrialDto";

export const checkTrialEligibleClient = (clientId: string) => {
  return http.get<[ICwbServiceDto]>(
    `${process.env.REACT_APP_REG_Api}/PerformerTrial/services/client/${clientId}`,
  );
}
export const checkTrialEligibleCountry = (countryIsoCode: string) => {
  return http.get<[ICwbServiceDto]>(
    `${process.env.REACT_APP_REG_Api}/PerformerTrial/services/country/${countryIsoCode}`,
  );
};
