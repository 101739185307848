import React, { Fragment } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  getCurUserSignUp,
  getSessionId,
  getCountryCode,
  setRegionCode,
  getCustomCouponCode,
  getUserActorSignUpId,
  getCustomProductInfo,
  deleteCustomProductInfo,
  timedSentrySendThenErrorPage,
  getWebSessionKey,
} from "../../../helpers/index";
// @ts-ignore
import { CWBDropdown, CWBCheckbox } from "cwb-react";
import { withRouter } from "react-router-dom";

import { iRootState } from "../../../store";
import BackButton from "../../Common/BackButton";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import { BlendedStyledInput } from "components/Common";

import {
  ErrorText,
  CheckboxWrapper,
  Section,
  InnerSection,
  SectionTitle,
  StyledCheckbox,
  Divider,
  StyledBlueBtn,
  LargeText,
  Spinner,
} from "../../Common/StyledComponents";
import CWBLabsActorFilmCenterStep from "components/CWBLabs/ActorFilmCenterStep";
import {
  IActorFilmCenter,
  IActorError,
  IActorAccountDto,
} from "../../../shared/api/dtos/IActorDto";
import { IGroupedCountry } from "../../../helpers/ICountryList";
import * as ActorJobsApi from "../../../shared/api/ActorJobs.api";
import ResidenceModal from "../../Modals/residenceModal";
import { IHttpResponse } from "shared/http";
import { ISignupResidence } from "shared/api/dtos/ISignupDto";
import { checkTrialEligibleClient, checkTrialEligibleCountry } from "shared/api/PerformerTrial";

enum Plans {
  YEARLY = 169,
  MONTHLY = 218,
}

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  cityList: any[];
  residenceModalOpen: boolean;
  residenceChecking: boolean;
  isAgentFind?: boolean;
}

//@ ts-check
// TODO: Move style and JSDOM out of container compnent  into Presentational Components
class ActorFilmCenterStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      onCamera: true,
      voice: true,
      country: "",
      province: "",
      provinceId: 1,
      city: "",
      cityId: 1,
      countryId: 1,
      sendBreakdowns: true,
      representedActor: false,
      memberId: ""
    },
    isFormSubmitClicked: false,
    cityList: [],
    residenceModalOpen: false,
    residenceChecking: false,
    isAgentFind: false
  };

  async componentDidMount() {
    const user: IActorAccountDto = getCurUserSignUp();
    if (getSessionId() && user) {
      if (this.props.groupCountryList.length <= 0)
        await this.props.getGroupCountryList();

      if (user.actorFilmCenter) this.mapUserToStateFields(user.actorFilmCenter);
      else {
        try {
          const grpCountries = this.props.groupCountryList;
          let country = grpCountries[0];

          let countryCode = getCountryCode()?.toUpperCase();
          if (!countryCode || countryCode == "") countryCode = "CA";

          country = grpCountries.find(
            (x) => x.country.countryCode?.toUpperCase() === countryCode
          );

          this.setCountry(country);

          const regionCode =
            country!.country!.locationCode || country!.country!.countryCode;
          setRegionCode(regionCode);
        } catch (error) {
          await this.props.getGroupCountryList();
        }
      }
    } else {
      window.location.replace("/");
    }
  }

  mapUserToStateFields = (userfilmCenter: IActorFilmCenter) => {
    if (!userfilmCenter) return;
    let fields: IActorFilmCenter = this.state.fields;

    fields.province = userfilmCenter.province;
    fields.provinceId = userfilmCenter.provinceId;
    fields.city = userfilmCenter.city;
    fields.cityId = userfilmCenter.cityId;
    fields.countryId = userfilmCenter.countryId;
    fields.onCamera = userfilmCenter ? userfilmCenter.onCamera : false;
    fields.voice = userfilmCenter ? userfilmCenter.voice : false;
    fields.sendBreakdowns = userfilmCenter ? userfilmCenter.sendBreakdowns : false;

    fields.representedActor = userfilmCenter ? userfilmCenter.representedActor : false;
    fields.unionActor = userfilmCenter ? userfilmCenter.unionActor : false;
    fields.memberId = userfilmCenter.memberId;

    this.setState({ fields });

    const grpCountries = this.props.groupCountryList;
    const country = grpCountries.find((x) => x.id === fields.countryId);
    this.setCountry(country);

    const regionCode =
      country!.country!.locationCode || country!.country!.countryCode;
    setRegionCode(regionCode);
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IActorFilmCenter = this.state.fields;
    let errors: IActorError = {} as IActorError;

    //projectTypeToCast
    if (!fields.onCamera && !fields.voice)
      errors.OnCamera = t("Select at least one type");

    //country
    if (!fields.country) errors.Country = t("Country field is required");

    //city
    if (!fields.city) errors.City = t("City field is required");

    if (fields.unionActor) {
      if (!fields.memberId) {
        errors.MemberId = t("Membership ID is required");
      } else {
        if (!fields.memberId.match(/^[0-9 \.\-`]+$/i)) {
          errors.MemberId = t("Only numbers are accepted");
        }

        if (fields.memberId.length < 6) {
          errors.MemberId = t("Membership ID is invalid");
        }
      }
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  setCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    try {
      const cityList = option.cityList
        ? option.cityList.map((city: any) => ({
          label: city.cityName,
          value: city.cityName,
          id: city.cityId,
          city,
        }))
        : [];
      fields.country = option.value;
      fields.countryId = option.id;
      this.setState({ fields });
      this.setState({ cityList });
    } catch (err) {
      // nothing needed here... an unhandled error sometimes occurs if the country.cities are not loaded yet and the user click the city dropdown
    }
  };

  handleChange = (field: string, option: any) => {
    let fields = this.state.fields;
    fields[field] = option;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCity = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = option.value;
    fields.cityId = option.id;
    fields.provinceId = option.city!.provinceId;
    fields.province = option.city!.provinceName;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleSendBreakdowns = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.sendBreakdowns = newChoice;
    this.setState({ fields });
  }

  handleRepresentedActor = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.representedActor = newChoice;
    this.setState({ fields });
  }

  handleUnionActor = (newChoice: any) => {
    let fields: IActorFilmCenter = { ...this.state.fields };
    fields.unionActor = newChoice;
    if (!fields.unionActor) {
      fields.memberId = '';
    }
    this.setState({ fields });
  }

  handleCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = '';
    fields.cityId = 1;

    fields.country = option.value;
    fields.countryId = option.id;
    fields.provinceId = option.country!.provinceId;
    fields.province = option.country!.provinceName;
    this.setState({ fields });
    this.setCountry(option);

    const regionCode =
      option!.country!.locationCode || option!.country!.countryCode;
    setRegionCode(regionCode);

    this.state.isFormSubmitClicked && this.checkValidation();
  };

  saveAndCheckPromo = async (e: any, nextPath: string) => {
    e.preventDefault();

    const availablePlansCountry = await checkTrialEligibleCountry(getCountryCode());

    const isMonthlyAvailable = availablePlansCountry.data.some(thisPlan => thisPlan.serviceId === Plans.MONTHLY);
    const isYearlyAvailable = availablePlansCountry.data.some(thisPlan => thisPlan.serviceId === Plans.YEARLY);

    if (!isMonthlyAvailable && !isYearlyAvailable) {
      this.saveAccountSignUp(nextPath);
    } else {

      await this.props.createActorResidence(this.state.fields);
      await this.props.createActorAccountPartial();

      const sessionId = getSessionId();

      if (!sessionId) {
        window.location.href = `${process.env.REACT_APP_CWB_Membership_Base}/promo?k=${getWebSessionKey()}`;
      } else {
        window.location.href = `${process.env.REACT_APP_CWB_Membership_Base}/promo?k=${getWebSessionKey()}&sessionId=${sessionId}`;
      }
    }
  }

  saveAccountSignUp = (nextPath: string) => {
    const { location } = this.props;
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    if (
      qs.parse(location.search).bd &&
      (qs.parse(location.search).page === "openbd" ||
        qs.parse(location.search).page === "submission")
    ) {
      this.setState({
        residenceChecking: true,
      });
      this.checkResidenceArea(nextPath);
    } else {
      this.continueSaveup(nextPath);
    }
  };

  checkResidenceArea = async (nextPath: string) => {
    const { location } = this.props;
    const { fields } = this.state;
    const { cityId } = fields;
    const bdId = qs.parse(location.search).bd;
    const sessionId = getSessionId();
    const page = qs.parse(location.search).page;
    try {
      const filterResponse = await ActorJobsApi.getFilterResult(
        sessionId,
        bdId,
        cityId,
        page
      );
      if (
        filterResponse.status === 200 &&
        filterResponse.data.countryMismatch
      ) {
        this.setState({
          residenceModalOpen: true,
          residenceChecking: false,
          isAgentFind: filterResponse.data.isAgentFind
        });
      } else {
        this.setState({
          residenceChecking: false,
          isAgentFind: filterResponse.data?.isAgentFind
        });
        this.continueSaveup(nextPath);
      }
    } catch (err) {
      this.setState({
        residenceChecking: false,
      });
      this.continueSaveup(nextPath);
    }
  };

  continueSaveup = async (nextPath: string) => {
    let user: IActorAccountDto = getCurUserSignUp();
    user.actorFilmCenter = this.state.fields;

    const qsResult = qs.parse(window.location.search);
    user.dev = qsResult.dev as string;
    await this.props.saveActorSignUp(user);

    this.moveToNext(nextPath);
  };

  moveToNext = (nextPath: string) => {

    const qsResult = qs.parse(window.location.search);
    if (!qsResult.promo) {
      qsResult.promo = getCustomCouponCode(getUserActorSignUpId());
      if (!qsResult.promo) {
        delete qsResult.promo;
      }
    }

    if (!qsResult.product) {
      const productInfo = getCustomProductInfo(getUserActorSignUpId());
      if (productInfo) {
        deleteCustomProductInfo(getUserActorSignUpId());
        qsResult.product = productInfo?.productCode;
        if (!qsResult.product) {
          delete qsResult.product;
        }
      }
    }


    if (!nextPath) {
      nextPath = "/actor/5";
    }

    if (this.props.errors.errorMessage) {
      if (this.props.validationError) {
        this.setState({ isFormSubmitClicked: false });
      } else {
        window.location.replace(`${process.env.REACT_APP_CWB_500}`);
      }
    } else if (!this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {

      if (this.state.isAgentFind) {
        nextPath = "/actor/payment";
      } else if (!qsResult.promo &&
        this.state.fields.unionActor && this.state.fields.memberId
        && this.state.fields.memberId.length >= 6
        && this.state.fields.countryId == 2) {
        this.props.setIsLoading(true);
        this.props.createFreemiumUser(getUserActorSignUpId()).then(response => {
          this.props.setClientId(response.data.clientId);
          this.props.setIsLoading(false);
          const params = qs.stringify({
            sag: true,
            ...(qs.parse(this.props.location.search))
          });

          this.props.history.push(`/actor/free-completed?${params}`);
        }).catch((ex2) => {
          timedSentrySendThenErrorPage(ex2);
        }).finally(() => {
          this.props.setIsLoading(false);
        });
      } else {
        this.props.history.push({
          pathname: nextPath,
          search: qs.stringify(qsResult)
        });
      }
    }
  };

  getCountry = (): IGroupedCountry => {
    const fields: IActorFilmCenter = this.state.fields;
    let country = this.props.groupCountryList.find(
      (x) => x.id === fields.countryId
    );
    if (fields.province && fields.country) return country;

    const qsArgs = qs.parse(this.props.location.search);
    let countryCode = getCountryCode();
    if (countryCode)
      country = this.props.groupCountryList.find(
        (x) => x.value === countryCode || x.value?.toUpperCase() == countryCode.toUpperCase()
      );
    else if (qsArgs.ctry)
      country = this.props.groupCountryList.find(
        (x) => x.value === qsArgs.ctry || x.value?.toUpperCase() == qsArgs.ctry.toString().toUpperCase()
      );

    return country;
  };

  toHomePage = () => {
    const { location } = this.props;
    const bdId = qs.parse(location.search).bd;
    window.location.href = `${process.env.REACT_APP_CWB_Site}/open-breakdown.asp?bd=${bdId}`;
  };

  render() {
    const { t, location } = this.props;
    const errors: IActorError = this.props.errors;
    const fields: IActorFilmCenter = this.state.fields;
    const city = this.state.cityList.find((x) => x.id === fields.cityId);
    const country = this.getCountry();

    // // Stripe limitation. Stripe Key defined on App start. Cannot be changed based on Selection.
    // // TODO: Have Stripe Provider wrap only ActorPaymentStep and based on country code props set appropriate Stripe Key
    // // Temp solution. Have User form X country only X country's cities
    // const countryList =
    //   this.props.groupCountryList &&
    //   this.props.groupCountryList.filter(
    //     (x) => x.country.countryCode === getCountryCode()
    //   );

    return qs.parse(location.search).cwblabs ? (
      <CWBLabsActorFilmCenterStep
        country={country}
        city={city}
        countryList={this.props.groupCountryList}
        cityList={this.state.cityList}
        errors={errors}
        fields={fields}
        isLoading={this.props.isLoading}
        onChange={this.handleChange}
        onChangeCountry={this.handleCountry}
        onChangeCity={this.handleCity}
        onSubmit={this.saveAccountSignUp}
      />
    ) : (
      <PageContainer>
        <BackButton width={480} />
        <Wrapper>
          <StyledTitle>{t("Customize your account")}</StyledTitle>
          <Section>
            <SectionTitle>
              {t("What type of projects are you looking for?")}
            </SectionTitle>
            <StyledSectionDiv>
              <CheckboxWrapper
                onClick={() => this.handleChange("onCamera", !fields.onCamera)}
              >
                <StyledCheckbox
                  className="small-cb"
                  isChecked={fields.onCamera}
                />
                <StyledImg alt="camera" src="../images/camera.svg" />
                <StyledCbLabel>{t("On-Camera")}</StyledCbLabel>
              </CheckboxWrapper>
              <CheckboxWrapper
                onClick={() => this.handleChange("voice", !fields.voice)}
              >
                <StyledCheckbox className="small-cb" isChecked={fields.voice} />
                <StyledImg alt="voice" src="../images/voice.svg" />
                <StyledCbLabel>{t("Voice")}</StyledCbLabel>
              </CheckboxWrapper>
            </StyledSectionDiv>
            <ErrorText>{t(errors.OnCamera) || errors.OnCamera}</ErrorText>
          </Section>
          <Divider></Divider>
          <Section className="margin-bottom-none">
            <LargeText>{t("Film center")}</LargeText>
            <FilmCenterContent>
              {t("Select your closest location from the list below.")}
            </FilmCenterContent>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your country")}</SectionTitle>
            <CWBDropdown
              value={country}
              isValueSelected={true}
              placeholder={t("Select Country")}
              items={this.props.groupCountryList}
              onChange={(option: any) => this.handleCountry(option)}
              className={errors.Country ? "validationError" : ''}
            />
            <ErrorText>{t(errors.Country) || errors.Country}</ErrorText>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your nearest city")}</SectionTitle>
            <CWBDropdown
              name="city"
              value={city || null}
              isValueSelected={true}
              placeholder={t("Select an Option")}
              items={this.state.cityList ? this.state.cityList : []}
              onChange={(option: any) => this.handleCity(option)}
              className={errors.City ? "validationError" : ''}
            />
            <ErrorText>{t(errors.City) || errors.City}</ErrorText>
          </Section>
          <Section className="margin-top-none ">
            <InnerSection>
              <div onClick={() => this.handleSendBreakdowns(!fields.sendBreakdowns)}>
                <InlineCheckbox isChecked={fields.sendBreakdowns} id="breakdowns-check" />
                <StyledCustomLabel htmlFor="breakdowns-check">{t("Send me breakdowns for my city")}</StyledCustomLabel>
              </div>
              {country?.id === 2 || country?.id == 18 ?
                (
                  <div onClick={() => this.handleRepresentedActor(!fields.representedActor)}>
                    <InlineCheckbox isChecked={fields.representedActor} id="representedActor-check" />
                    <StyledCustomLabel htmlFor="representedActor-check">{t("I'm a represented actor")}</StyledCustomLabel>
                  </div>
                ) : (<></>)}

              {country?.id === 2 ?
                (<>

                  <div onClick={() => this.handleUnionActor(!fields.unionActor)}>
                    <InlineCheckbox isChecked={fields.unionActor} id="unionActor-check" />
                    <StyledCustomLabel htmlFor="unionActor-check">{t("I'm a US SAG-AFTRA member")}</StyledCustomLabel>
                  </div>

                  {fields.unionActor ?
                    (<div style={{ marginRight: "25px" }}>
                      <SectionTitle>{t("SAG-AFTRA member ID")}</SectionTitle>
                      <BlendedStyledInput style={{ marginTop: "8px", maxWidth: "290px" }}
                        error={Boolean(errors.MemberId)}
                        errorMessage={t(errors.MemberId) || errors.MemberId}
                        name="memberId"
                        maxLength="12"
                        value={fields.memberId}
                        onChange={(e: any) => this.handleChange("memberId", e.target.value)}
                      />
                    </div>) : <></>
                  }
                </>
                ) : (<></>)}
            </InnerSection>
          </Section>
          <ErrorText style={{ textAlign: 'center' }}> {t(errors.errorMessage) || errors.errorMessage}</ErrorText>
          <Section>
            <StyledBlueBtn
              disabled={this.props.isLoading}
              onClick={(e) => this.saveAndCheckPromo(e, "/actor/5")}
            >
              {t("Continue")}
              {((!this.state.residenceModalOpen && this.props.isLoading) ||
                this.state.residenceChecking) && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
            </StyledBlueBtn>
          </Section>

        </Wrapper>
        <Footer />
        {this.state.residenceModalOpen && (
          <ResidenceModal
            onClose={() =>
              this.setState({
                residenceModalOpen: false,
              })
            }
            handleUpgrade={() => this.continueSaveup("/actor/5")}
            toHomePage={this.toHomePage}
          />
        )}
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
  validationError?: boolean;
  clientId: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    validationError: state.actorModel.validationError,
    groupCountryList: state.referencesModel.supportedGroupCountryList,
    isLoading: state.actorModel.isLoading,
    clientId: state.actorModel.clientId,
  };
}

interface DispatchProps {
  saveCastingFilmCenter: (fields: any) => void;
  getGroupCountryList: () => void;
  setErrors: (errors: IActorError) => void;
  saveActorSignUp: (dto: IActorAccountDto) => void;
  createFreemiumUser: (actorSignUpId: number) => Promise<IHttpResponse<any>>;
  setClientId: (clientId: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  createActorResidence: (dto: ISignupResidence) => void;
  createActorAccountPartial: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    saveCastingFilmCenter: dispatch.actorModel.saveCastingFilmCenter,
    getGroupCountryList: dispatch.referencesModel.getSupportedGroupCountryList,
    setErrors: dispatch.actorModel.setErrors,
    saveActorSignUp: dispatch.actorModel.saveActorSignUp,
    createFreemiumUser: dispatch.actorModel.createFreemiumUser,
    setClientId: dispatch.actorModel.setClientId,
    setIsLoading: dispatch.actorModel.setIsLoading,
    createActorResidence: dispatch.actorModel.createActorResidence,
    createActorAccountPartial: dispatch.actorModel.createActorAccountPartial,
  };
}

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ActorFilmCenterStep))
);

const InlineCheckbox = styled(CWBCheckbox)`
  display:inline-block;
`
const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.midGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown-wrapper .validationError {
    .cwb-dropdown__control {
      border-color: ${(p) => p.theme.errorColor} !important;
    }
    .cwb-dropdown__placeholder {
      color: ${(p) => p.theme.errorColor} !important;
    }
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTitle = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]};
  margin-bottom: 8px;
`;

const StyledSectionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, 0, 0.25, 1)};
`;

const StyledCbLabel = styled.span`
  font-size: ${(p) => p.theme["xxs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const StyledCustomLabel = styled.label`
  font-size: ${(p) => p.theme["xs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 16px;
  cursor: pointer;
`;

const FilmCenterContent = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-size: ${(p) => p.theme["s-font-size"]};
  font-weight: normal;
`;
