import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n } from "react-i18next";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled, { css } from "styled-components";
import BackButton from "../Common/BackButton";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import Tooltip from "../Common/Tooltip";
import {
  Section,
  SectionTitle,
  StyledInput,
  SmallTextError,
  StyledBlueBtn,
  Spinner,
} from "../Common/StyledComponents";
import CWBLabsFooter from "components/CWBLabs/components/Footer";
import { iRootState } from "../../store";
import { ISubscriptionService } from "../../shared/api/dtos/IServicesDto";
import {
  IStripeResponse,
  IStripeCharge,
  ICreditCard,
  ISubscriptionResponse,
  IStripePaymentError,
  IStripePaymentForm,
} from "../../shared/api/dtos/IStripeDto";
import { isValidEmail, postalCodeValidation } from "../../helpers/validations";
import EmailUpgradeModal from "components/Modals/EmailUpgradeModal";
import { findTrackingData, timedSentrySendThenErrorPage } from "helpers";

interface IProps extends StateProps, DispatchProps, RouteComponentProps {
  t?: any;
  stripe?: any;
  country: string;
  provinceId: number;
  allowSkip?: boolean;
  onSubmit: (apiPayload: IStripeCharge) => void;
  moveToNext: (serviceId: number) => void;
  onSkip?: () => void;
}

interface IState {
  fields: any;
  subtotal: string;
  tax: string;
  total: string;
  currencySymbol: string;
  isEmailModalOpen: boolean;
}

class MembershipOneTime extends React.Component<IProps, IState> {
  state: IState = {
    fields: {
      name: "",
      postalCode: "",
      serviceId: 0,
      addressLine1: "",
    } as IStripePaymentForm,
    subtotal: "0.00",
    tax: "0.00",
    total: "0.00",
    currencySymbol: "$",
    isEmailModalOpen: false,
  };

  async componentDidMount() {
    try {
      this.setService();
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  }

  setService = () => {
    let fields = this.state.fields;
    const service = this.props.oneTimeService;
    fields.serviceId = service.serviceId;
    const total = this.getTotal(service);
    this.setState({ fields });
    if (service.currency === "EUR") this.setState({ currencySymbol: "€" });
    this.updatePrice(
      this.getSubTotal(service),
      this.getTax(service.oneTimeFee, service.tax).toFixed(2),
      total
    );
  };

  getTax = (price: number, tax: number): number => {
    if (!price || !tax) return 0;

    return (price * tax) / 100;
  };

  getSubTotal = (service: ISubscriptionService): string => {
    if (!service || !service.oneTimeFee) return "0.00";
    return service.oneTimeFee.toFixed(2);
  };

  getTotal = (service: ISubscriptionService): string => {
    if (!service || !service.oneTimeFee) return "0.00";
    const tax = this.getTax(service.oneTimeFee, service.tax);

    return (service.oneTimeFee + tax).toFixed(2);
  };

  updatePrice = (subtotal: string, tax: string, total: string) => {
    this.setState({ total });
    this.setState({ tax });
    this.setState({ subtotal });
  };

  handleChange = async (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };
  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IStripePaymentForm = this.state.fields;
    let errors = {} as IStripePaymentError;

    if (!fields.name) errors.name = t("Name on Card field is required");

    if (!fields.addressLine1) errors.addressLine1 = t("Address is required");

    if (!fields.postalCode)
      errors.addressZip = t("Postal / Zip Code field is required");

    const validationError = postalCodeValidation(
      fields.postalCode.replace(/\s/g, ""),
      t
    );
    if (validationError) errors.addressZip = validationError;

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  isUpgrade = () => {
    return this.props.actorProfile;
  };

  hasValidActorProfileEmail = () => {
    const actorProfile = this.props.actorProfile;
    if (actorProfile && actorProfile.email && isValidEmail(actorProfile.email))
      return true;

    this.setState({ ...this.state, isEmailModalOpen: true });
    return false;
  };

  handleCloseEmailModalOpen = () => {
    this.setState({ ...this.state, isEmailModalOpen: false });
  };

  handleSubmit = async () => {
    if (this.props.isLoading || !this.checkValidation()) return;
    let fields = this.state.fields;

    if (this.isUpgrade() && !this.hasValidActorProfileEmail()) return;

    try {
      this.props.setIsLoading(true);
      let { token, error } = await this.props.stripe.createToken();

      if (error && error.message) {
        let errors = {} as IStripePaymentError;
        // TODO: how to translate erorr message? check i18 doc
        errors.errorMessage = error.message;
        this.props.setErrors(errors);
        this.props.setIsLoading(false);
        return;
      }

      const stripeResponse: IStripeResponse = token;

      const apiPayload = {} as IStripeCharge;
      apiPayload.token = stripeResponse.id;

      apiPayload.card = {} as ICreditCard;
      apiPayload.card.brand = stripeResponse.card.brand;
      apiPayload.card.expMonth = stripeResponse.card.exp_month;
      apiPayload.card.expYear = stripeResponse.card.exp_year;
      apiPayload.card.last4 = stripeResponse.card.last4;

      apiPayload.card.name = fields.name;
      apiPayload.card.addressZip = fields.postalCode.replace(/\s/g, "");
      apiPayload.card.addressLine1 = fields.addressLine1;
      apiPayload.couponCode = "";
      apiPayload.serviceId = fields.serviceId;
      apiPayload.provinceId = this.props.provinceId;

      apiPayload.trackingData = findTrackingData(this.props.location.search);
      await this.props.onSubmit(apiPayload);

      this.moveToNext(apiPayload.serviceId);
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  };

  moveToNext = (serviceId: number) => {
    if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.moveToNext(serviceId);
    }
  };

  handleSkip = () => {
    this.props.onSkip();
  };

  render() {
    const { t, location, fromPrecheckout } = this.props;
    const errors: IStripePaymentError = this.props.errors;
    const fields: IStripePaymentForm = this.state.fields;
    const serv: ISubscriptionService = this.props.oneTimeService;
    const currencySymbol = this.state.currencySymbol;

    const isTablet = window.matchMedia("(max-width: 768px)").matches;

    // const isCWBLabs = qs.parse(location.search).cwblabs;
    const isCWBLabs = false;
    const subtotal = this.state.subtotal;

    return (
      <StyledPageContainer isCWBLabs={isCWBLabs}>
        {fromPrecheckout && <BackButton width={isTablet ? 690 : 880} />}
        <Wrapper isCWBLabs={isCWBLabs}>
          <StyledTitle>{t("Start your Membership")}</StyledTitle>
          <RowColumnWrapper>
            <Row>
              <ColumnBilling>
                <BillingWrapper>
                  <Section className="margin-bottom-none">
                    <SectionLargeTitle>{t("Billing")}</SectionLargeTitle>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Name on card")}</SectionTitle>
                    <StyledInput
                      name="name"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.name}
                    />
                    <SmallTextError className="error">
                      <span>{errors.name}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    {/* <SectionTitle>{t("Card Details")}</SectionTitle> */}
                    <CardElement
                      hidePostalCode={true}
                      className="form-control"
                    />
                    <SmallTextError className="error">
                      <span>{errors.errorMessage}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Billing Address")}</SectionTitle>
                    <StyledInput
                      name="addressLine1"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.addressLine1}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressLine1}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {t("Billing Postal / Zip Code")}
                    </SectionTitle>
                    <StyledInput
                      name="postalCode"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.postalCode}
                      // onBlur={this.handleOnBlurPostal}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressZip}</span>
                    </SmallTextError>
                  </Section>
                  <InfoText>
                    {t(
                      `By completing this purchase, I will enter into Agreement with Casting Workbook Services, Inc. ("Casting Workbook"). For the price of my enrolment, my photograph(s) and other information will be placed online in Casting Workbook, on my agent's roster (if represented) or the Talent Scout database (if unrepresented). I understand that my photograph(s) and other portfolio materials will be made available to casting director members and talent agent members (where applicable) for the duration of my membership.`
                    )}
                  </InfoText>
                  <InfoText>
                    {t(
                      `I understand and agree that Casting Workbook makes no guarantee or promise, either expressed or implied, that I will obtain work, but only that my photograph(s) and other information supplied by myself will be made available to industry professionals utilizing the Casting Workbook database.`
                    )}
                  </InfoText>
                  <Section>
                    <StyledBlueBtn
                      disabled={this.props.isLoading}
                      onClick={this.handleSubmit}
                    >
                      {t("Complete Purchase")}
                      {this.props.isLoading && (
                        <Spinner
                          src="../images/spinner.svg"
                          className="spinner-width"
                        />
                      )}
                    </StyledBlueBtn>
                  </Section>
                </BillingWrapper>
              </ColumnBilling>
              <ColumnMembership>
                <MembershipContainer>
                  <MembershipWrapper>
                    <Section className="margin-bottom-none">
                      <SectionLargeTitle>
                        {t("Actor Membership")}
                      </SectionLargeTitle>
                    </Section>
                    <Section>
                      {serv && (
                        <>
                          <RadioWrapper key={serv.serviceId}>
                            <RadioInput
                              type="radio"
                              id={
                                serv.serviceId ? serv.serviceId.toString() : ""
                              }
                              name={"service"}
                              value={serv.serviceId}
                              checked={
                                this.state.fields.serviceId === serv.serviceId
                              }
                            />
                            <RadioStyledInput />
                            <RadioLabelTitle
                              htmlFor={
                                serv.serviceId ? serv.serviceId.toString() : ""
                              }
                            >
                              {serv.description}
                            </RadioLabelTitle>
                            <RadioLabel
                              htmlFor={
                                serv.serviceId ? serv.serviceId.toString() : ""
                              }
                            >
                              {t("{{currencySymbol}}{{subtotal}} + tax", {
                                currencySymbol,
                                subtotal,
                              })}
                            </RadioLabel>
                          </RadioWrapper>
                        </>
                      )}
                    </Section>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <Section>
                      <SectionTitle>{t("Special Offer")}</SectionTitle>
                      <WrapperDiscount>
                        <InputDiscount
                          value="special21"
                          name="couponCode"
                          disabled={true}
                        />
                        <ButtonDiscount disabled={true}>
                          {t("Applied")}
                        </ButtonDiscount>
                      </WrapperDiscount>
                    </Section>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Subtotal")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.subtotal}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Tax")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.tax}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper>
                  <DueTodayDivider />
                  <DueTodayWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Due Today")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.total}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </DueTodayWrapper>
                </MembershipContainer>
              </ColumnMembership>
            </Row>
          </RowColumnWrapper>
        </Wrapper>
        {this.state.isEmailModalOpen && (
          <EmailUpgradeModal onClose={() => this.handleCloseEmailModalOpen()} />
        )}
        {isCWBLabs ? <StyledCWBLabsFooter /> : <Footer />}
      </StyledPageContainer>
    );
  }
}

interface StateProps {
  oneTimeService: ISubscriptionService;
  subscriptionDetails: ISubscriptionResponse;
  errors: IStripePaymentError;
  isLoading: boolean;
  actorProfile: any;
  fromPrecheckout: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    oneTimeService: state.servicesModel.oneTimeService,
    subscriptionDetails: state.stripeModel.subscriptionDetails,
    errors: state.stripeModel.errors,
    isLoading: state.stripeModel.isLoading,
    actorProfile: state.appModel.actorProfile,
    fromPrecheckout: state.servicesModel.fromPrecheckout,
  };
}

interface DispatchProps {
  createStripeSubscription: (dto: IStripeCharge) => void;
  setErrors: (errors: IStripePaymentError) => void;
  setIsLoading: (loading: boolean) => any;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    setErrors: dispatch.stripeModel.setErrors,
    setIsLoading: dispatch.stripeModel.setIsLoading,
  };
}

const StyledPageContainer = styled(PageContainer)`
  ${(p) =>
    p.isCWBLabs &&
    css`
      background-color: #040f1c;

      @media all and (max-width: 520px) {
        padding-top: 8px;
        border: none;

        & > div:first-of-type {
          display: none;
        }
      }
    `}
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  width: 880px;
  padding: 40px 60px;

  @media all and (max-width: 768px) {
    width: 690px;
    margin: 0;
    padding: 24px 24px 40px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    margin: 0;
    padding: 24px 16px 40px;

    ${(p) =>
      p.isCWBLabs &&
      css`
      width: 100%;
      min-width: 40%;
      padding: 24px 16px 40px
      border-top: 0;
      border-radius: 0;
    `}
  }
`;

const RowColumnWrapper = styled.div`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media all and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

const ColumnBilling = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 12px;
  min-width: 420px;
  background-color: #f2f2f7;
  border-radius: 4px;
  padding: 8px 40px 8px;
  margin-top: 24px;
  margin-right: 3%;
  height: fit-content;

  @media all and (max-width: 768px) {
    min-width: 360px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    min-width: unset;
    margin: 16px 0;
    padding: 12px 16px;
  }
`;

const ColumnMembership = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100%;
  justify-content: start;
  // align-items: center;
  background-color: ${(p) => p.theme.white};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-top: 24px;
  height: 100%;
`;

const MembershipContainer = styled.div`
  border: ${(props) => props.theme.dropDown["border"]} !important;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const SectionLargeTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const BillingWrapper = styled.div``;

const MembershipWrapper = styled.div`
  padding: 8px 40px 18px;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DueTodayWrapper = styled.div`
  padding: 8px 40px 18px;
  background-color: #f2f2f7;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfoText = styled.div`
  margin-top: 24px;
  font-size: 9px !important;
`;

const PriceTextWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 7px 0 0 0;
`;

const PriceTextBox = styled.div`
  overflow: auto;
  margin: 0;
`;

const PriceTextLeft = styled.div`
  float: left;
`;

const PriceTextRight = styled.div`
  float: right;
`;

const PriceTextClear = styled.div`
  clear: both;
`;

const WrapperDiscount = styled.div`
  margin-top: 10px;
  display: flex;
`;

const InputDiscount = styled.input`
  padding: 6px;
  font-size: 14px;
  border: 1px solid grey;
  float: left;
  flex: 1;
  border: 1px solid #a6aebc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  color: #a6aebc;
`;

const ButtonDiscount = styled.button`
  float: left;
  width: auto;
  padding: 6px 12px;
  background: white;
  color: #0bb07b !important;
  font-size: 14px;
  border: 1px solid #0bb07b;
  cursor: not-allowed;
  border-radius: 0 4px 4px 0;
`;

const Divider = styled.div`
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const DueTodayDivider = styled.div`
  margin: 8px 0 0 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const RadioWrapper = styled.button`
  background-color: ${(p) => p.theme.white};
  text-align: left;
  &:focus {
    outline: none;
  }
  & + & {
    margin-top: 8px;
  }

  display: inline-block;
  position: relative;
  line-height: ${(props) => props.theme.formField["font-size"]};

  .fake-radio-button {
    fill: white;
  }

  input:disabled:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.formField.disabled["color"]};
  }

  .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.outline};
  }

  input:not(:disabled):hover + div svg .styled-radio-button-outline,
  input:not(:disabled):checked + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.lightBlue};
  }

  input:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.lightBlue};
  }

  input:focus + div svg {
    box-shadow: 0 0 6px rgba(25, 99, 246, 0.6);
    border-radius: 100%;
  }

  input:disabled + div,
  input:disabled,
  input:disabled ~ label {
    cursor: not-allowed;
  }

  input:disabled + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.disabled["color"]};
  }

  border: 1px solid #d3dde9 !important;
  border-radius: 4px;
  padding: 8px 8px 0;
  width: 100%;
`;

const RadioInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15px;
  height: 15px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
`;

const RadioLabelTitle = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: ${(props) => props.theme.formField["font-size"]};
  font-weight: ${(props) => props.theme.formField["font-weight"]};
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioLabel = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: 12px;
  font-weight: 100;
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioStyledInput = () => (
  <StyledInputWrapper
    dangerouslySetInnerHTML={{
      __html: `<svg width="15" height="15" viewBox="0 0 15 15" style="overflow: visible">
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-69.000000, -293.000000)">
        <g transform="translate(69.000000, 268.000000)">
          <g transform="translate(0.000000, 25.000000)">
            <circle fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
            <path class="styled-radio-button-outline" d="M7.5,15 C11.6421356,15 15,11.6421356 15,
              7.5 C15,3.35786438 11.6421356,0 7.5,0 C3.35786438,0 0,3.35786438 0,7.5 C0,
              11.6421356 3.35786438,15 7.5,15 Z M7.5,14 C11.0898509,14 14,11.0898509 14,
              7.5 C14,3.91014913 11.0898509,1 7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,
              11.0898509 3.91014913,14 7.5,14 Z" fill="#AFAFAF"></path>
            <circle fill="white" cx="7.5" cy="7.5" r="3.5" class="styled-radio-button-center"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
    }}
  />
);

const StyledInputWrapper = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  z-index: 1;
`;

const StyledCWBLabsFooter = styled(CWBLabsFooter)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

export default withI18n()(
  injectStripe(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(MembershipOneTime))
  )
);
