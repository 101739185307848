import React from 'react';
import qs from 'query-string';
import { getI18n, withI18n } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CWBLabsLogo from 'images/cwblabs-logo.svg';
import CWBLabsLogoMobile from 'images/icon-cwb-logo.svg';
import { Typography } from 'components/Common';

type Props = {
  t: any;
} & RouteComponentProps;

const isMobile = window.matchMedia("(max-width: 520px)").matches;

const Header: React.FC<Props> = ({ t, location }) => {
  const params = qs.stringify({
    cwblabs: true,
    redirect: 'cwblabs',
    ...(qs.parse(location.search))
  });

  const isLangSpanish = getI18n().language === "es";

  const showSignInButton = (
    location.pathname.includes('register') ||
    location.pathname.includes('register/actor') ||
    location.pathname.includes('register/actors') ||
    location.pathname.includes('register/agent') ||
    location.pathname.includes('register/agents') ||
    location.pathname.includes('register/casting') ||
    location.pathname.includes('register/performer') ||
    location.pathname.includes('register/performers') ||
    location.pathname.includes('register/cwblabs/actor') ||
    location.pathname.includes('select-account-type')
  );

  const loginRedirect = params? `${process.env.REACT_APP_CWB_Site}/login/?${params}`: `${process.env.REACT_APP_CWB_Site}/login/`;

  return (
    <StyledDiv>
      <a href={`${process.env.REACT_APP_CWB_LABS_Site}${isLangSpanish ? "/es" : ""}`}>
        <img
          alt="Casting Workbook Labs"
          src={isMobile ? CWBLabsLogoMobile : CWBLabsLogo}
        />
      </a>
      {showSignInButton && (
        <RightContainer>
          <StyledTypography>{t("Already have an account?")}</StyledTypography>
          <SignInButton href={loginRedirect}>
          {t('Sign in')}
        </SignInButton>
        </RightContainer>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(0, 4)};
  background-color: #040F1C;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.8));

  @media all and (max-width: 520px) {
    padding-right: ${(p) => p.theme.spacing(2)};
  }
  @media all and (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

const SignInButton = styled.a`
  && {
    color: white;
    height: 40px;
    padding: ${(p) => p.theme.spacing('10px', 1)};
    background-color: #02B8F9;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #0892E5;
    }

    &:active {
      background-color: #0584D7;
    }
  }
`;

const RightContainer = styled.div`
display:flex;
align-items:center;
`;

const StyledTypography = styled(Typography)`
  color: #FFFFFF;
  margin-right:12px;
  font-size:13px;
  @media all and (max-width: 520px) {
    display: none;
  }
`;


export default withI18n()(withRouter(Header));
