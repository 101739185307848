import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import {
  VerifyPhoneCodeLength,
  getCurUserSignUp,
  getSessionId,
  getCountryCode,
} from "../../../helpers";
import PhoneInput from "react-phone-input-2";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
  Overlay,
} from "../../Common/StyledComponents";
import { iRootState } from "../../../store";
import {
  IActorError,
  IActorPhone,
  IActorAccountDto,
} from "../../../shared/api/dtos/IActorDto";
import { ICountryDto } from "../../../shared/api/dtos/IReferencesDto";
import { CodeInput, Typography } from "components/Common";
import { phoneValidation } from "../../../helpers/validations";
import { IBaseSignupCredentials, ISignupAccountDto } from "shared/api/dtos/ISignupDto";
import VerificationInput from "react-verification-input";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  canResend: boolean;
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
  phoneChanging: boolean;
  newPhone: string;
  isChangingSubmitClicked: boolean;
}

class ActorVerifyPhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    canResend: true,
    isFormSubmitClicked: false,
    fields: { phone: "", code: "" },
    phoneChanging: false,
    newPhone: "",
    isChangingSubmitClicked: false,
  };

  async componentDidMount() {
    const sessionId = getSessionId();
    if (!sessionId) {
      // window.location.replace(process.env.REACT_APP_CWB_500);
    }
    const user: ISignupAccountDto = getCurUserSignUp();

    if (user && !this.state.fields.phone) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...user.signupDetails,
        },
      });
    }

    if (!this.props.countryCode && !getCountryCode()) {
      const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
      if (qsArgs.dev && qsArgs.ctry) {
        await this.props.setForceRequesterCountryCode(qsArgs.ctry.toString())
      } else {
        try {
          await this.props.setRequesterCountryCode();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (
      !fields.code ||
      !fields.code.match(/^\d{4}/i) ||
      fields.code.length !== VerifyPhoneCodeLength
    ) {
      errors.Code = t(
        "Invalid code, only numbers and a code length of {{length}} is expected.",
        { length: VerifyPhoneCodeLength }
      );
      fields.code = "";
      this.setState({ fields });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.code || fields.code.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Code = t("Only numbers are accepted");
    fields.code = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  checkPhoneValidation() {
    const { t } = this.props;
    let errors = {} as IActorError;
    if (!this.state.newPhone) {
      errors.NewPhone = t("Invalid Phone Number");
    } else if (phoneValidation(this.state.newPhone, t))
      errors.NewPhone = phoneValidation(this.state.newPhone, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleCodeChange = (code: string) => {
    let fields = this.state.fields;
    fields.code = code;
    this.setState({ fields });
    this.checkNumber();

    if (fields.code.length === 4) this.verifyPhone();
  };

  handleChangeNewPhone = (phoneNumber: string) => {
    this.setState({
      newPhone: phoneNumber,
    });
    this.checkNumber();
    this.state.isChangingSubmitClicked && this.checkPhoneValidation();
  };

  getNumber = (): string => {
    return `+${this.state.newPhone}`;
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      this.confirmChangePhone(event);
    }
  };

  confirmChangePhone = async (e: any) => {
    e.preventDefault();
    if (this.props.isLoading) return;
    this.setState({ isChangingSubmitClicked: true });
    if (!this.checkPhoneValidation()) return;
    // await this.props.createActorPhone({ phone: this.getNumber() });
    await this.props.createActorAccount(this.props.actorCredentials);
    const user: IActorAccountDto = getCurUserSignUp();
    if (user) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...user.actorCredentials,
        },
      });
    }
    this.setState({
      phoneChanging: false,
      newPhone: "",
      isChangingSubmitClicked: false,
    });
  };

  verifyPhone = async () => {
    // e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.verifyActorPhone(this.state.fields.code);
    this.moveToNext();
  };

  resendCode = async (e: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    // if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    this.setState({ canResend: false });
    await this.props.createActorPhone(user.actorPhone);
    setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  };

  changePhone = () => {
    this.setState({
      phoneChanging: true,
    });
  };

  cancelChangePhone = () => {
    this.setState({
      phoneChanging: false,
    });
  };

  // changeToEmail = () => {
  //   this.props.setSelectPhone(false);
  //   this.props.history.push({
  //     pathname: "/actor/1",
  //     search: this.props.location.search,
  //   });
  // };

  moveToNext = () => {
    if (this.props.errors.errorMessage){}
      // window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/3",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { t, location, countryCode } = this.props;
    const userCountryCode = countryCode || getCountryCode();
    const { canResend, fields, phoneChanging, newPhone } = this.state;

    return (
      <PageContainer>
        {phoneChanging ? (
          <Wrapper>
            <Title>{t("Change your Phone Number")}</Title>
            <Section>
              <SectionTitle>{t("Existing Phone Number")}</SectionTitle>
              <StyledInputDisable value={fields.phone} disabled />
            </Section>
            <Section>
              <SectionTitle>{t("New Phone Number")}</SectionTitle>
              <PhoneInput
                containerStyle={{ marginTop: "8px" }}
                country={userCountryCode ? userCountryCode.toLocaleLowerCase() : ""}
                value={this.state.newPhone}
                onChange={(phoneNumber) =>
                  this.handleChangeNewPhone(phoneNumber)
                }
                onEnterKeyPress={this.handleKeyPress}
              />
              <SmallTextError className="error">
                <span>{this.props.errors.Phone}</span>
              </SmallTextError>
            </Section>
            <SmallTextError className="error">
              <span>{this.props.errors.NewEmail}</span>
            </SmallTextError>
            <Section>
              <StyledBlueBtn onClick={this.confirmChangePhone} disabled={this.props.isLoading}>
                {t("Confirm")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledBlueBtn>
            </Section>
            <StyledAnchor onClick={this.cancelChangePhone}>
              {t("Cancel")}
            </StyledAnchor>
          </Wrapper>
        ) : (
          <Wrapper>
            <Title>{t("Enter the code we sent you")}</Title>
            <SubTitle>
              {t("We sent you a {{length}} digit code to", {
                length: VerifyPhoneCodeLength,
              })} <b>{fields && fields.phone}</b>
            </SubTitle>
            {/* <StyledTypography variant="bodyBold">
              {fields && fields.phone}
              <ReplaceImg
                onClick={this.changePhone}
                src="/images/replace.svg"
                alt=""
              />
            </StyledTypography> */}
            <form
              name="verifyForm"
              className="verifyForm"
              onSubmit={this.verifyPhone}
              style={{ width: "100%" }}
            >
              <fieldset>
                <Section>
                  {/* <SectionTitle>{t("Verification Code")}</SectionTitle> */}
                  {/* <StyledInput
                    name="code"
                    type="text"
                    className={this.props.errors.Code ? "invalid" : ""}
                    maxLength={VerifyPhoneCodeLength}
                    onChange={this.handleChange}
                    value={fields.code}
                    autoFocus
                  /> */}
                  <CodeInput
                    autoFocus
                    length={4}
                    onChange={this.handleCodeChange}
                    value={fields.code}
                    validChars={"0-9"}
                    isLoading={this.props.isLoading}
                  />
                  <SmallTextError className="error">
                    <span>{this.props.errors.Code}</span>
                  </SmallTextError>
                </Section>
                {/* <Section>
                  <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                    {t("Verify Phone")}
                    {this.props.isLoading && (
                      <Spinner
                        src="../images/spinner.svg"
                        className="spinner-width"
                      />
                    )}
                  </StyledBlueBtn>
                </Section> */}
              </fieldset>
            </form>
            <StyledAnchor disabled={!canResend} onClick={this.resendCode}>
              {canResend ? t("Resend code") : t("Code resent!")}
            </StyledAnchor>
            <StyledAnchor disabled={!canResend} onClick={this.changePhone}>
              {t("Change phone number")}
            </StyledAnchor>
            {/* <StyledAnchor onClick={this.changeToEmail}>
              {t("Verify By Email")}
            </StyledAnchor> */}
          </Wrapper>
        )}
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  countryList: ICountryDto[];
  isLoading: boolean;
  countryCode: string;
  actorCredentials: IBaseSignupCredentials;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    countryList: state.referencesModel.countryList,
    isLoading: state.actorModel.isLoading,
    countryCode: state.referencesModel.countryCode,
    actorCredentials: state.actorModel.actorCredentials,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorPhone: (fields: IActorPhone) => void;
  getCountryList: () => void;
  verifyActorPhone: (code: string) => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
  createActorAccount: (actorCredentials: IBaseSignupCredentials) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorPhone: dispatch.actorModel.createActorPhone,
    getCountryList: dispatch.referencesModel.getCountryList,
    verifyActorPhone: dispatch.actorModel.verifyActorPhone,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
    createActorAccount: dispatch.actorModel.createActorAccount,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorVerifyPhoneStep)
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.lightBlue};
    }

    ${(p) =>
      p.disabled &&
      css`
        color: ${(p) => p.theme.typography.color.disabled};
        cursor: default;

        &:hover {
          color: ${(p) => p.theme.typography.color.disabled};
        }
      `}
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }

  &:focus {
    border-color: #0f9bf0;
  }
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
  margin-bottom: 14px;
`;

const SubTitle = styled.div`
  color: ${(p) => p.theme.color} !important;
  margin: auto 8px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 16px;
`;

const StyledTypography = styled(Typography)`
  color: #53627c;
`;

const ReplaceImg = styled.img`
  width: 12px;
  height: 12px;
  margin: ${(p) => p.theme.spacing(0, 0, 0, 1)};
  cursor: pointer;
`;

export const StyledInputDisable = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: #f1f2f4;
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;
  color: #8a94a6;
`;

const VerificationInputWrapper = styled.div`
  margin: 0 auto 40px;
  position: relative;

  & input{
    cursor: default;
  }

  & .vi__container{
    margin: 0 auto;
    justify-content: center;
    height: 40px;
    gap: 10px;
  }

  & .vi__character{
    cursor: text;
    max-width: 40px;
    position: relative;
    border-radius: 4px;

    border-color: ${p => p.theme.palette.grey[3]};
    background-color: ${p => p.theme.palette.grey[1]};
    color: ${p => p.theme.palette.brand.darkBlue};
    font-size: 18px;
    font-weight: normal;
    line-height: 40px;

    &--selected{
      outline-color: ${p => p.theme.palette.brand.blue};
      border-color: ${p => p.theme.palette.brand.blue};
    }

    &::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 90%;
      background-color: ${p => p.theme.palette.grey[1]};
      transform: translate(-50%, -50%);
    }

    &--filled{
      &::after{
        opacity: 0;
      }
    }
  }
`;
