import { createModel } from "@rematch/core";
import * as ReferencesApi from "../shared/api/References.api";
import { groupCountryList, setBrowserLang, setCountryCode, trySetSpanishBrowserLang } from "../helpers";
import { ICountryDto } from "../shared/api/dtos/IReferencesDto";
import { ITrialServiceData } from "../shared/api/dtos/IServicesDto";
import { IGroupedCountry } from "../helpers/ICountryList";
import * as Sentry from "@sentry/react";
import { getI18n } from "react-i18next";

const referencesModel = createModel({
  state: {
    countryList: [] as ICountryDto[],
    groupCountryList: [] as IGroupedCountry[],
    supportedGroupCountryList: [] as IGroupedCountry[],
    countryCode: "",
    trialServiceData: [] as ITrialServiceData[]
  },
  reducers: {
    updateCountryList(state, data) {
      return { ...state, countryList: data };
    },
    updateGroupCountryList(state, data) {
      return { ...state, groupCountryList: data };
    },
    updateSupportedGroupCountryList(state, data) {
      return { ...state, supportedGroupCountryList: data };
    },
    updateCountryCode(state, data) {
      return { ...state, countryCode: data };
    },
    updateTrialServiceData(state, data){
      return {... state, trialServiceData: data}
    }
  },
  effects: (dispatch: any) => ({
    async setTrialServiceData(payload) {
      const { join, country, data } = payload
      const col = (dispatch.referencesModel.trialServiceData || []) as ITrialServiceData[];

      const found = col.findIndex(a => a.join == join && a.country == country);
      if (found > -1) {
        col.splice(found, 1);
      }
      
      col.push( {
        join : join,
        country: country,
        data : data
      })

      dispatch.referencesModel.updateTrialServiceData(col);
    },
    async getGroupCountryList() {
      const resp = await ReferencesApi.getCountriesWithCities();
      const grouped = groupCountryList(resp.data);
      dispatch.referencesModel.updateGroupCountryList(grouped);
    },
    async getCountryList() {
      const resp = await ReferencesApi.getCountries();
      dispatch.referencesModel.updateCountryList(resp.data);
    },
    async getSupportedGroupCountryList() {
      const resp = await ReferencesApi.getSupportedCitiesCountries();
      const grouped = groupCountryList(resp.data);
      dispatch.referencesModel.updateSupportedGroupCountryList(grouped);
    },
    async setRequesterCountryCode(fallBack: string = "") {
      try {
        // API has retry policy for this HTTP client
        const resp = await ReferencesApi.getRequesterCountryCode(fallBack);
        const countryCode = resp.data.countryCode;
        const regionCode = resp.data.regionCode;
        if (countryCode)  {
          setCountryCode(countryCode);
        }
        
        // spanish or latam country
        if (!countryCode || countryCode == "ES") {
          trySetSpanishBrowserLang();
        } else {
          if (!regionCode || (regionCode == "ES" || regionCode == "LATAM")) {
            trySetSpanishBrowserLang();
          }
        }

        dispatch.referencesModel.updateCountryCode(resp.data.countryCode);
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
        let fallbackCountry = "CA";
        if (getI18n().language === "es") fallbackCountry = "ES";
        setCountryCode(fallbackCountry);
        dispatch.referencesModel.updateCountryCode(fallbackCountry);
      }
    },
    async setForceRequesterCountryCode(countryCode: string) {
      setCountryCode(countryCode);
      dispatch.referencesModel.updateCountryCode(countryCode);
    }
  }),
});

export default referencesModel;
