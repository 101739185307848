import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withI18n } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  Footer,
  Modal,
  PageContainer,
  Typography,
} from "../../Common";
import BackButton from "../../Common/BackButton";
import { Spinner } from "../../Common/StyledComponents";
import UpgradeTableNew from "../../Common/UpgradeTableNew";
import {
  getCurUserSignUp,
  getSessionId,
  getUserActorSignUpId,
  timedSentrySendThenErrorPage,
  getCustomCouponCode,
} from "../../../helpers";
import qs from "query-string";
// @ts-ignore
import * as Sentry from "@sentry/react";
import PayAsYouGoModal from "../../Modals/PayAsYouGoModal";

interface Props extends StateProps, DispatchProps, RouteComponentProps {
  t: any;
}

const ActorRegistrationUpgradeTable: React.FC<Props> = ({
  t,
  history,
  location,
  yearlyPerMonthCharge,
  createFreemiumUser,
  currencySymbol,
  setClientId,
  postCurrencySymbol,
  bdSubmissionCharge,
  customCouponCode,
  isJobSubmissionFree
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!getSessionId() || !getCurUserSignUp()) {
      // window.location.replace("/");
    }
  }, []);

  const completeFreemiumRegistration = async () => {
    try {
      setIsLoading(true);
      const response = await createFreemiumUser(getUserActorSignUpId());
      setClientId(response.data.clientId);
      setIsLoading(false);
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
    history.push({
      pathname: "/actor/free-completed",
      search: location.search,
    });
  };

  let charge = yearlyPerMonthCharge?.toString() ?? "";
  if (currencySymbol === "€" && yearlyPerMonthCharge) {
    charge = yearlyPerMonthCharge.toString().replace(".", ",");
  }

  let promo = qs.parse(location.search).promo;

  if (!promo) {
    promo = customCouponCode;
  }

  return (
    <PageContainer>
      <BackButton width={800} />
      <StyledModal>
        <Typography component="h1" gutterBottom variant="h2" align="center">
          {t("Sign up as a Lite Member or Upgrade now")}
        </Typography>
        <StyledTypography align="center" color="medGrey">
          {/* eslint-disable-next-line */}
          {t(
            "Get started with a Lite Member or Upgrade and get everything Casting Workbook has to offer for as little as {{currencySymbol}}{{charge}}. Not sure what to choose? No worries, you can upgrade later at any time!",
            {
              currencySymbol,
              charge,
              postCurrencySymbol
            }
          )}
        </StyledTypography>
        <UpgradeTableNew promo={promo} />
        <StyledButtonGroup>
          <StyledButton
            disabled={isLoading}
            onClick={() =>
              history.push({
                pathname: "/actor/payment",
                search: location.search,
              })
            }
          >
            {t("Upgrade now")}
          </StyledButton>
          <StyledButton
            disabled={isLoading}
            variant="secondary"
            onClick={() => {
              if (isJobSubmissionFree) {
                completeFreemiumRegistration();
              } else {
                setModalOpen(true);
              }
             
            }}
          >
            {t("Lite Member")}{" "}
            {isLoading && <StyledSpinner src="/images/spinner.svg" />}
          </StyledButton>
        </StyledButtonGroup>
      </StyledModal>
      <PayAsYouGoModal
        onClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
        handleUpgrade={() =>
          history.push({
            pathname: "/actor/payment",
            search: location.search,
          })
        }
        freeSignUp={completeFreemiumRegistration}
        isLoading={isLoading}
      />
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(5, 14, 6)};

  @media all and (max-width: 768px) {
    width: 90%;
    padding: ${(p) => p.theme.spacing(3, 5)};
  }

  @media all and (max-width: 520px) {
    width: 100%;
    padding: 24px 10px;
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
  font-size: 13px;
  line-height: 18px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledSpinner = styled(Spinner)`
  margin-left: ${(p) => p.theme.spacing(0.5)};
`;

const StyledButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  font-weight: bold;
`;

interface StateProps {
  yearlyPerMonthCharge: number;
  currencySymbol: string;
  postCurrencySymbol?: string,
  bdSubmissionCharge?: any
  customCouponCode?: string
  isJobSubmissionFree?: boolean
}

const mapStateToProps = (state: any) => {
  let postCurrencySymbol = "";
  if (state.premiumModel.postCurrencySymbol) {
    postCurrencySymbol = " " + state.premiumModel.postCurrencySymbol.toString();
  }

  let customCouponCode = state.actorModel.customCouponCode;
  if (!customCouponCode) {
    customCouponCode = getCustomCouponCode(getUserActorSignUpId());
  }

  return {
    yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
    currencySymbol: state.premiumModel.currencySymbol,
    postCurrencySymbol: postCurrencySymbol,
    bdSubmissionCharge: state.premiumModel.bdSubmissionCharge,
    customCouponCode: customCouponCode,
    isJobSubmissionFree: state.premiumModel.isJobSubmissionFree
  }
};

interface DispatchProps {
  createFreemiumUser: (actorSignUpId: number) => any;
  setClientId: (clientId: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  createFreemiumUser: dispatch.actorModel.createFreemiumUser,
  setClientId: dispatch.actorModel.setClientId,
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorRegistrationUpgradeTable)
);
