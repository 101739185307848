import React from "react";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import {
  errorPageRedirect,
  getCurUserSignUp,
  getSessionId,
} from "../../../helpers";
import { isValidEmail } from "helpers/validations";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
} from "../../Common/StyledComponents";
import { iRootState } from "../../../store";
import {
  IActorError,
  IActorAccountDto,
  IActorEmail,
  IActorCredentials,
} from "../../../shared/api/dtos/IActorDto";
import { CodeInput, Typography } from "components/Common";
import EnvelopeIcon from "../../../images/envelope.svg";
import SuccessCheckIcon from "../../../images/success-check.svg";
import SpinnerBlueIcon from "../../../images/spinner_blue.svg";
import { IBaseSignupCredentials, ISignupAccountDto } from "shared/api/dtos/ISignupDto";

const RESEND_TIMEOUT = 30;
const RESENT_MESSAGE_DURATION = 5;
const CODE_LENGTH = 6;

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  canResend: boolean;
  resendTimer: number,
  isFormSubmitClicked: boolean;
  fields: IActorEmail;
  emailChanging: boolean;
  newEmail: string;
  codeStatus: { type: "info" | "error", message: string };
  // isSuccessTransition: boolean;
}

class ActorVerifyEmailStep extends React.Component<IProps, IState> {
  public state: IState = {
    canResend: true,
    resendTimer: 0,
    emailChanging: false,
    isFormSubmitClicked: false,
    fields: { email: "", code: "" },
    newEmail: "",
    codeStatus: { type: "info", message: null},
    // isSuccessTransition: false,
  };

  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    
    this.resendTimerDecrement();

    const sessionId = getSessionId();
    if (!sessionId) {
      // window.location.replace(process.env.REACT_APP_CWB_500);
      // errorPageRedirect("Missing session id");
        this.props.history.push({
        pathname: "/actor",
        search: this.props.location.search,
      });
    }
    const user: ISignupAccountDto = getCurUserSignUp();

    if (!this.props.actorCredentials || !user?.signupCredentials) {
      // this.props.history.push({
      //   pathname: "/actor",
      //   search: this.props.location.search,
      // });
      window.location.replace("/actor");
    } else if (user?.signupCredentials && !this.props.actorCredentials) {
      // set actor credentials
      this.props.setActorCredentials(user.signupCredentials);
    } else if (user.signUpStep >= 3) {
      if (this.props.isEmailVerified) {
        this.moveToNext();
      } else {
        this.props.history.push({
          pathname: "/actor",
          search: this.props.location.search,
        });
      }
    }
 
    if (user && !this.state.fields.email) {
      this.setState({
        fields: {
          ...this.state.fields,
          email: user.signupCredentials?.email,
        },
      });
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (
      !fields.code ||
      !fields.code.match(/^\d{4}/i) ||
      fields.code.length !== CODE_LENGTH
    ) {
      errors.Code = t(
        "Invalid code, only numbers and a code length of {{length}} is expected.",
        { length: CODE_LENGTH }
      );
      // fields.code = "";
      this.setState({ fields });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkEmail() {
    const { t } = this.props;
    const { newEmail } = this.state;
    let errors = {} as IActorError;

    if (!newEmail || !isValidEmail(newEmail)) {
      errors.Email = t("Email is invalid.");
      // this.setState({ newEmail: "" });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.code || fields.code.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Code = t("Only numbers are accepted");
    fields.code = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleCodeChange = (code: string) => {
    if (this.state.isFormSubmitClicked) return;
    
    let fields = this.state.fields;
    fields.code = code;
    this.setState({ fields });
    this.checkNumber();

    // Submit automatically when the full code is written
    if (code.length === CODE_LENGTH) {
      // @ts-ignore
      document.activeElement.blur();  // blur the code input when it's finished written
      this.verifyEmail();
    }
  };

  handleChangeNewEmail = (e: any) => {
    this.setState({
      newEmail: e.target.value,
    });

    this.props.setErrors({} as IActorError);
  };

  verifyEmail = async (e?: any) => {
    if (e) e.preventDefault();
    
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;

    await this.props.verifyActorEmail(this.state.fields.code);
    
    if (!this.props.errors.Code) {
      this.props.setEmailVerified(true);
      this.moveToNext();
    }
    else
      this.setState({ isFormSubmitClicked: false });
  };

  resendCode = async (e: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) errorPageRedirect("Missing user account") /* window.location.replace(`${process.env.REACT_APP_CWB_500}`) */;
    this.setState({
      canResend: false,
    });
    // let ae = this.state.fields;
    // if (email) ae.email = email;
    await this.props.verifyEmailResendCode(this.state.fields.email);
    this.setState({
      canResend: false,
      resendTimer: RESEND_TIMEOUT + RESENT_MESSAGE_DURATION,
    });

    this.resendTimerDecrement();
    // setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  };

  changeEmail = () => {
    this.setState({
      emailChanging: true,
    });
  };

  cancelChangeEmail = () => {
    this.setState({
      emailChanging: false,
    });
  };

  confirmChange = async (e: any) => {
    e.preventDefault();
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) errorPageRedirect("Missing user account")/* window.location.replace(`${process.env.REACT_APP_CWB_500}`) */;
    
    const { newEmail } = this.state;
    if (!this.checkEmail()) return;

    const isSameEmail = newEmail === this.props.actorCredentials.email;
    let resendTimer = this.state.resendTimer;

    if (isSameEmail) {
      if (this.state.resendTimer <= 0) {
        await this.resendCode(e);
        resendTimer = RESEND_TIMEOUT;
      }
    } else {
      // await this.props.createActorAccount({
      //   email: newEmail,
      //   password: this.props.actorCredentials.password,
      // });

      await this.props.verifyEmailResendCode(newEmail);

      resendTimer = RESEND_TIMEOUT;
      if (this.props.errors.Email || this.props.errors.Password) {
        return;
      }
    }

    this.setState({
      fields: {
        ...this.state.fields,
        email: newEmail,
        code: '',
      },
      emailChanging: false,
      newEmail: "",

      canResend: false,
      resendTimer,
    });
  };

  changeVerifyMethod = () => {
    this.props.setSelectPhone(true);
  };

  // resendCode = async (e: any) => {
  //   e.preventDefault();
  //   if (!this.state.canResend) return;
  //   const user: IActorAccountDto = getCurUserSignUp();
  //   if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
  //   this.setState({ canResend: false });
  //   await this.props.createActorPhone(user.actorPhone);
  //   setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  // };

  /* changePhone = () => {
    this.props.history.goBack();
  }; */

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      errorPageRedirect("There were errors when moving to next step");
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      // this.setState({ isSuccessTransition: true });

      setTimeout(() => {
        this.props.history.push({
          pathname: "/actor/3",
          search: this.props.location.search,
        });
      }, 4000);
    }
  };

  resendTimerDecrement = () => {
    const resendTimer = this.state.resendTimer - 1;

    if (resendTimer <= 0) {
      this.setState({
        resendTimer,
        canResend: true,
      });
      return;
    }

    this.setState({
      resendTimer,
    });
    
    setTimeout(this.resendTimerDecrement, 1000);
  }

  render() {
    const { t } = this.props;
    const {
      canResend,
      fields,
      emailChanging,
      newEmail,
      resendTimer,
      // isSuccessTransition,
      isFormSubmitClicked,
    } = this.state;

    return (
      <PageContainer>
        {emailChanging
          ? (
              <Wrapper>
                <Title>{t("Change your email")}</Title>
                <form
                  onSubmit={this.confirmChange}
                  style={{ width: "100%" }}
                >
                  <Section>
                    <SectionTitle>{t("Existing Email")}</SectionTitle>
                    <StyledInputDisable value={fields.email} disabled />
                  </Section>
                  <Section>
                    <SectionTitle>{t("New Email")}</SectionTitle>
                    <StyledInput
                      value={newEmail}
                      type="email"
                      onChange={this.handleChangeNewEmail}
                    />
                  </Section>
                  <SmallTextError className="error">
                    <span>{this.props.errors.Email}</span>
                  </SmallTextError>
                  <Section>
                    <StyledBlueBtn disabled={this.props.isLoading}>
                      {t("Confirm")}
                      {this.props.isLoading && (
                        <Spinner
                          src="../images/spinner.svg"
                          className="spinner-width"
                        />
                      )}
                    </StyledBlueBtn>
                  </Section>
                  <ResendSection>
                    <ResendButton onClick={this.cancelChangeEmail}>
                      {t("Cancel")}
                    </ResendButton>
                  </ResendSection>
                </form>
              </Wrapper>
            )
          : (
              <Wrapper>
                {/* <SuccessScreen show={this.props.isEmailVerified}>
                  <SuccessIcon src={SuccessCheckIcon} />
                  <SuccessMessage>Code verified!</SuccessMessage>
                </SuccessScreen> */}
                {this.props.isEmailVerified || this.state.isFormSubmitClicked
                  ? <SuccessScreen show={this.props.isEmailVerified}>
                      <SuccessIcon src={SuccessCheckIcon} />
                      <SuccessMessage>{t("Code verified")}!</SuccessMessage>
                    </SuccessScreen>
                  : null}
                <VerifyIcon
                  src={EnvelopeIcon}
                />
                <Title>{t("Verify your email")}</Title>
                <SubTitle>
                  {t("Enter the {{length}} digit code we sent to", { length: CODE_LENGTH })}
                </SubTitle>
                <Email variant="bodyBold">
                  {fields && fields.email}
                  <ReplaceImg
                    onClick={this.changeEmail}
                    src="/images/replace.svg"
                    alt=""
                  />
                </Email>
                {/* <UnderTitle dangerouslySetInnerHTML={{__html: t(`If you don’t receive the email in 30 seconds, please check your spam folder.`) }}>
                </UnderTitle> */}
                <form
                  name="verifyForm"
                  className="verifyForm"
                  onSubmit={this.verifyEmail}
                  style={{ width: "100%" }}
                >
                  <fieldset>
                    <Section>
                      <CodeInput
                        autoFocus
                        length={CODE_LENGTH}
                        onChange={this.handleCodeChange}
                        value={fields.code}
                        validChars={"0-9"}
                        isInvalid={!!this.props.errors.Code}
                        isDisabled={this.state.isFormSubmitClicked}
                      />
                    </Section>
                    {/* <Section>
                      <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                        {t("Verify Email")}
                        {this.props.isLoading && (
                          <Spinner
                            src="../images/spinner.svg"
                            className="spinner-width"
                          />
                        )}
                      </StyledBlueBtn>
                    </Section> */}
                  </fieldset>
                </form>
                {this.props.errors.Code?.length ? <StatusMessage>{this.props.errors.Code}</StatusMessage> : null}
                <ResendSection isHidden={this.props.isEmailVerified}>
                  {isFormSubmitClicked
                    ? <SpinnerBlueWrapper><SpinnerBlue src={SpinnerBlueIcon} /></SpinnerBlueWrapper>
                    : resendTimer <= RESEND_TIMEOUT
                      ? <ResendButton disabled={!canResend} onClick={this.resendCode}>
                          {resendTimer <= 0 ? t("Resend code") : t("Resend code in {{count}} seconds", { count: resendTimer })}
                        </ResendButton>
                      : <ResentMessage>{t("Code resent!")}</ResentMessage>
                  }
                </ResendSection>
                <Typography>{t("Don't forget to check the spam folder")}!</Typography>
              </Wrapper>
            )
          }
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};
  position: relative;

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 30px;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const ResendSection = styled.div<{ isHidden?: boolean }>`
  text-align: center;
  margin-bottom: 32px;
  ${p => p.isHidden && `
    opacity: 0;
    cursor: default;
  `}
`;

const ResentMessage = styled(Typography)`
  font-size: ${(p) => p.theme["m-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${p =>  p.theme.palette.grey[6]};
  padding: 1px 0 .5px;
`;

const ResendButton = styled.button<{ disabled?: boolean }>`
  border: none;
  background-color: transparent;

  color: #00a2e0;
  font-size: ${(p) => p.theme["xs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  letter-spacing: 0.21px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.lightBlue};
  }

  ${(p) =>
    p.disabled &&
    css`
      color: ${(p) => p.theme.typography.color.disabled};
      cursor: default;

      &:hover {
        color: ${(p) => p.theme.typography.color.disabled};
      }
    `}
`;

const SpinnerBlueWrapper = styled.div`
  height: 19px;
`;

const SpinnerBlue = styled.img`
  width: 34px;
  height: 34px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
`;

const StatusMessage = styled(Typography)`
  color: ${p => p.theme.palette.brand.red};
  font-size: 14px;
  margin-bottom: 20px;
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }
`;

export const StyledInputDisable = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: #f1f2f4;
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;
  color: #8a94a6;
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
  margin-bottom: 16px;
`;

const SubTitle = styled.div`
  color: ${(p) => p.theme.color} !important;
  margin: 8px 8px auto 8px;
  font-weight: normal;
  text-align: center;
`;

const UnderTitle = styled.div`
  margin: 8px 8px 0 8px;
// color: ${(p) => p.theme.errorColor} !important;
  color: #c7053f !important;
  font-weight: normal;
  text-align: center;
`;

const VerifyIcon = styled.img`
  width: 40px;
  margin-bottom: 24px;
`;

const Email = styled(Typography)`
  color: #53627c;
  margin-bottom: 16px;
  whitespace: no-wrap;
  position: relative;
`;

const ReplaceImg = styled.img`
  position: absolute;
  left: calc(100% + 3px);
  top: 4px;
  width: 12px;
  height: 12px;
  margin: ${(p) => p.theme.spacing(0, 0, 0, 0.5)};
  cursor: pointer;
`;

const SuccessScreen = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99999;
  display: flex;
  flex-flow: nowrap column;
  justify-content: center;
  align-items: center;

  transition: opacity 1s;
  ${p => p.show
    ? 'opacity: 1;'
    : `
      opacity: 0;
      // display: none;
    `
  }

  & > *{
    transition: opacity 1s 1s;
    ${p => p.show
      ? 'opacity: 1;'
      : `
        opacity: 0;
        // display: none;
      `
    }
  }
`;

const SuccessIcon = styled.img`
  margin-bottom: 24px;
  height: 60px;
`;

const SuccessMessage = styled(Typography)`
  font-size: 28px;
`;

interface StateProps {
  errors: IActorError;
  fields: IActorEmail;
  actorCredentials: IBaseSignupCredentials;
  isLoading: boolean;
  isEmailVerified: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    actorCredentials: state.actorModel.actorCredentials,
    fields: state.actorModel.fields,
    isLoading: state.actorModel.isLoading,
    isEmailVerified: state.actorModel.isEmailVerified,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  verifyActorEmail: (code: any) => void;
  verifyEmailResendCode: (email: string) => void;
  createActorAccount: (fields: IActorCredentials) => void;
  setEmailVerified: (isEmailVerified: boolean) => void;
  setActorCredentials: (actorCredentials: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    verifyActorEmail: dispatch.actorModel.verifyActorEmail,
    verifyEmailResendCode: dispatch.actorModel.verifyEmailResendCode,
    createActorAccount: dispatch.actorModel.createActorAccount,
    setEmailVerified: dispatch.actorModel.setEmailVerified,
    setActorCredentials: dispatch.actorModel.setActorCredentials,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorVerifyEmailStep)
);
